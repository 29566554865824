import React, { useState } from "react";
import {
  InboxOutlined,
  UploadOutlined,
  DesktopOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Upload,
  Input,
  Drawer,
  message,
  Modal,
} from "antd";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";

import "../../../assets/styles/admin.css";
import endpoints from "../../../services/endpoints";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);
    console.log(values);
    axios
      .post(endpoints.course.create, {
        data: values,
      })
      .then((res) => {
        setIsButtonLoading(false);
        form.resetFields();
        close();
      })
      .catch((e) => {
        setIsButtonLoading(false);
        console.log(e);
      });
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      key={`modal-create-course`}
      title="Adicionar curso"
      width={800}
      onCancel={handleClose}
      open={open}
      footer={[
        <Button size="large" disabled={isButtonLoading} onClick={handleClose}>
          Cancelar
        </Button>,
        <Button
          size="large"
          loading={isButtonLoading}
          type="primary"
          onClick={form.submit}
        >
          Adicionar
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        className="mt-10"
      >
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Nome" />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Slug" />
        </Form.Item>
        <Form.Item
          label="Posição"
          name="position"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <InputNumber className="w-100" size="large" placeholder="0" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Create;
