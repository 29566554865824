import React, { useEffect, useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import CountUp from 'react-countup';
import { Col, Row, Space, Statistic, Button, Form, Input, Checkbox } from 'antd';
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import BannerVideoImage from "../../../assets/images/bg@2x.png";
import { Outlet, Link, useNavigate } from "react-router-dom";
import dayjs from 'dayjs'
import config from "../../../services/config";
import './BannerCourses.css';

function BannerCourses({ data }) {

  const [video, setVideo] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      preLoadImages()
      setVideo(data);
    }
  }, [data]);

  function preLoadImages() {
    var img_banner = new Image();
    img_banner.src = data.image_banner;
    var img_thumb = new Image();
    img_thumb.src = data.image_thumbnail;
  }

  return (
    <div className="banner_video">
      <Row className="destaque_banner" style={{ backgroundImage: `url(${BannerVideoImage})`, height: 'calc(100vh - 85px' }}>
        <Col span={24} className="d-flex flex-column jc-end ai-end">
          <Row gutter={[{ xs: 20, sm: 8, md: 16, lg: 60 }, { xs: 0, sm: 8, md: 16, lg: 60 }]} className="text_square container m-auto">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div >
                <iframe
                  className="iframe-responsive"
                  width="100%"
                  height="600px"
                  frameBorder="0"
                  src={`${video?.link}`}
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="banner-video-col" >
              <h2 className="f-40 bold mb-20 white">{video?.title}</h2>
              <p className="f-26 normal white mb-20  ellipsis-destaque">{video?.description}</p>
              <p className="f-16 normal white mb-20">{dayjs(video?.date).format('DD-MM-YYYY')}</p>
              {/*}
              <Button onClick={() => navigate(`/artigos/${video?.slug}`)} type="primary" className="ler-artigo-btn">
                Ler guia relacionado
              </Button>
  {*/}
            </Col>
          </Row>
        </Col>
        <LinkScroll
          className="arrow_down"
          activeClass="active"
          to="section-list-videos"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <DownOutlined
            style={{ color: '#FFF' }}
          />
        </LinkScroll>
      </Row>
    </div>
  );
};

export default BannerCourses;
