import React, { useEffect, useState } from "react";
import { ArrowRightOutlined, EyeOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import config from "../../../services/config";

import {
  Col,
  Row,
  Card,
  Select,
  Space,
  Button,
  Form,
  Input,
  Checkbox,
} from "antd";
import ExemploGuiaPraticoImg from "../../../assets/images/guia-pratico-hipocoagulacao-auricular.png";
import FiltroIcon from "../../../assets/images/filtro-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import "../../../assets/styles/articles.css";
import "./ListGuiasPraticos.css";
import { Link } from "react-router-dom";
const { Meta } = Card;

const ListGuiasPraticos = ({ data }) => {
  const [guides, setGuides] = useState(data);
  const [guidesToShow, setGuidesToShow] = useState(guides);
  const [inputFilter, setInputFilter] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    setGuides(data);
    setGuidesToShow(data);
  }, [data]);

  function handleFilter(changedFields, allFields) {
    let inputLower =
      allFields.title?.length > 0 ? allFields.title?.toLowerCase() : "";
    if (!inputLower) {
      setGuidesToShow(guides);
      return;
    }

    let filteredItens = guides.filter((item) =>
      item.title.toLowerCase().includes(inputLower)
    );

    setGuidesToShow(filteredItens);
  }

  return (
    <Row className="container">
      <Col span={24} className="d-flex flex-column jc-center ai-center">
        <Row gutter={[24, 24]} className="jc-start mt-40">
          {guidesToShow.map((item, index) => {
            if (item != undefined) {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  key={"guide-" + index}
                >
                  <Link to={`/guias-praticos/${item?.slug}`}>
                    <div className="list-guide-item">
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${config.server_ip}/assets/media/${item?.image})`,
                        }}
                      ></div>
                      <div className="d-flex jc-sb w-100 p-20 flex-wrap">
                        <div
                          className="d-flex flex-column jc-center"
                          style={{ maxWidth: 300 }}
                        >
                          <p className="f-18 blue ellipsisTwo">{item?.title}</p>
                        </div>
                        <div className="minw-100 d-flex flex-column jc-center ai-center link">
                          <p>Ver mais</p>
                          <span>
                            <ArrowRightOutlined />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            }
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default ListGuiasPraticos;
