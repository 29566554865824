import React, { useEffect, useState } from "react";
import { Col, Row, Space, Button, Form, Input, Checkbox, Spin, message } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import "./Contact.css";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import GoogleReCAPTCHA from "../../../services/recaptcha";
import ImageCoverBg from "../../../assets/images/contactos-bg@2x.png";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.contact.create, {
        data: values,
      })
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          setIsButtonLoading(false);
          setFormSubmitted(true);
          form.resetFields();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        message.error({
          content: "Aconteceu algum erro, por favor tente novamente mais tarde! ",
        });
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <Helmet>
        <title>Formulário de contacto | Deixe aqui as suas dúvidas</title>
        <meta name="description" content="Deixe aqui as suas dúvidas" />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Deixe aqui as suas sugestões para que consigamos melhorar o Cardio4all" />
        <meta property="og:description" content="As suas sugestões vão nos ajudar a melhorar, contribua para a melhoria constante do Cardio4all." />
      </Helmet>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row
        className="h-100 w-100 pt-40 pb-40 contact_content"
        style={{
          minHeight: "calc(100vh - 150px)",
          backgroundImage: `url(${ImageCoverBg})`,
        }}
      >
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          {!formSubmitted ? (
            <Row className="login-row">
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="f-36 bold white mb-20">Sempre à distância de uma mensagem. </p>
                <p className="white mb-20">Preencha o formulário em baixo e esclareça a sua dúvida com a nossa equipa de profissionais.</p>
                <Form className="contact-form d-flex flex-column jc-center ai-center mb-40" name="basic" onFinish={handleSubmit} form={form}>
                  <Row gutter={[16, 8]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Nome" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Introduza um endereço de e-mail válido",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="E-mail" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Assunto" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="text"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <ReactQuill placeholder="Mensagem" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <p className="f-12 white">
                    Ao enviar mensagem, concordo com os{" "}
                    <Link className="white underline" target="_blank" to="/termos-e-condicoes">
                      termos e condições
                    </Link>
                    .
                  </p>
                  <Row gutter={[24]} className="mt-20">
                    <Col span={24} className="d-flex jc-center ai-center">
                      <Button loading={isButtonLoading} type="primary" htmlType="submit" className="submit-btn mr-10">
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          ) : (
            <Row className="login-row">
              <Col xs={24} sm={12} md={12} lg={12} xl={12} className="d-flex flex-column jc-center ai-center">
                <SmileOutlined className="mb-40 white" style={{ fontSize: 100 }} />
                <p className="f-26 white mb-60 text-center">
                  Obrigado por ter entrado em contacto connosco. Iremos responder para o <b>e-mail</b> que preencheu no formulário o mais <b>rápido possível</b>!
                </p>
                <Button loading={isButtonLoading} type="primary" onClick={() => setFormSubmitted(false)} className="contact-btn" disabled={!isVerified}>
                  Nova contacto
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Contact;
