import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Divider,
  Steps
} from 'antd';
import '../../../assets/styles/admin.css'
import axios from 'axios';
import api from '../../../services/api';
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { useForm } from "antd/es/form/Form";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import 'react-quill/dist/quill.snow.css';
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function ChooseColumns({ form, data, handleSubmit }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="bold blue f-20 mb-30 mt-30">Escolha as colunas que deseja exportar</p>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="columns">
          <Checkbox.Group>
            {Object.keys(data[0]).map((item) =>
              <Checkbox value={item}>{item}</Checkbox>
            )}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChooseColumns;