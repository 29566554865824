import React, { useEffect, useState } from "react";
import { Col, Row, Space, Button, Form, Input, Checkbox, Spin, DatePicker, Select, message } from "antd";
import ImageCoverBg from "../../../assets/images/bg-perfil@2x.png";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import endpoints from "../../../services/endpoints";
import axios from "axios";
import districts from "../../../services/districts";
import GoogleReCAPTCHA from "../../../services/recaptcha";
import { Helmet } from "react-helmet";

const Register = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [counties, setCounties] = useState([]);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, [user]);

  function handleVerifyUser() {
    console.log(user);
    if (user) {
      navigate("/perfil");
    }
  }

  function handleChangeDistrict(e) {
    form.setFieldValue("county", null);
    let auxCounties = districts.filter((item) => item.name === e)[0];
    setCounties(auxCounties.county);
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.auth.register, {
        data: values,
      })
      .then((res) => {
        if (res.data.user_registered) {
          navigate("/login");
          setIsButtonLoading(false);
        } else {
          message.error({ content: res.data.message });
          setIsButtonLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <Helmet>
        <title>Registo | Cardio4all | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Cardio4all | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row
        className="h-100 w-100 register_content"
        style={{
          minHeight: "calc(100vh - 150px)",
          backgroundImage: `url(${ImageCoverBg})`,
        }}
      >
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          <Row className="mt-40 d-flex jc-center ai-center">
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="d-flex flex-column jc-center ai-center">
              <Row gutter={[24]} className="w-100 ml-0 mr-0">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="f-20 blue mb-30">
                    Faça aqui o seu{" "}
                    <b>
                      <i>registo</i>
                    </b>
                    <br />
                    para poder aceder à sua conta.
                  </p>
                </Col>
              </Row>
              <Form className="register-form w-100" name="basic" form={form} onFinish={handleSubmit}>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Introduza o seu nome",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nome" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Introduza um endereço de e-mail válido",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="E-mail" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          min: 8,
                          message: "A password deve ter pelo menos 8 caracteres",
                        },
                      ]}
                    >
                      <Input.Password size="large" placeholder="Password" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="confirm_password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Confirme a sua password, por favor!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("As passwords não correspondem"));
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" placeholder="Confirmar Password" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p className="f-16 blue mb-20">Dados pessoais</p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="birth_date"
                      rules={[
                        {
                          required: true,
                          message: "Introduza a sua data de nascimento",
                        },
                      ]}
                    >
                      <DatePicker size="large" placeholder="Data de nascimento" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="order_nr">
                      <Input size="large" placeholder="Nº Ordem dos Médicos" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="specialty"
                      rules={[
                        {
                          required: true,
                          message: "Introduza a sua especialidade",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Profissão"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={[
                          {
                            value: "Cardiologia",
                            label: "Cardiologia",
                          },
                          {
                            value: "MGF",
                            label: "MGF",
                          },
                          {
                            value: "Medicina Interna",
                            label: "Medicina Interna",
                          },
                          {
                            value: "Outra",
                            label: "Outra",
                          },
                        ]}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p className="f-16 blue mb-20">Local de trabalho</p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="work_district"
                      rules={[
                        {
                          required: true,
                          message: "Introduza a sua data de nascimento",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Distrito"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        onChange={handleChangeDistrict}
                        options={districts.map((item) => ({ value: item.name, label: item.name }))}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="work_county">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Concelho"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={counties.map((item) => ({ value: item.name, label: item.name }))}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="work_local"
                      rules={[
                        {
                          required: true,
                          message: "Introduza a sua especialidade",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Local" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex jc-center ai-center">
                    <Form.Item
                      name="terms_and_conditions"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Este campo é obrigatório",
                        },
                      ]}
                      className="mb-10"
                    >
                      <Checkbox size="large" className="blue">
                        Concordo com os{" "}
                        <Link to="/termos-e-condicoes" target="_blank" className="blue">
                          Termos & Condições
                        </Link>
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex jc-center ai-center">
                    <Form.Item
                      name="health_professional"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Este campo é obrigatório",
                        },
                      ]}
                    >
                      <Checkbox size="large" className="blue">
                        Declaro que sou Profissional de Saúde
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24]} className="w-100 ml-0 mr-0">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex jc-center ai-center">
                    <Button loading={isButtonLoading} type="primary" htmlType="submit" className="register-btn mt-40" disabled={!isVerified}>
                      Registar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Register;
