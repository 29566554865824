import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import BannerVideocast from "../../../components/website/BannerVideocast/BannerVideocast";
import endpoints from "../../../services/endpoints";
import { Col, Row, Spin } from "antd";
import ListVideocasts from "../../../components/website/ListVideocasts/ListVideocasts";

import BannerVideoImage from "../../../assets/images/bg@2x.png";

export default function Videocast() {
  const [videos, setVideos] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [guides, setGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllArticles();
  }, []);

  const getAllArticles = () => {
    axios
      .get(endpoints.videos.read)
      .then((res) => {
        axios
          .get(endpoints.podcasts.read)
          .then((resPodcasts) => {
            axios
              .get(endpoints.guides.read)
              .then((resGuides) => {
                console.log(resGuides);
                setVideos(res.data[0]);
                setPodcasts(resPodcasts.data[0]);
                setGuides(resGuides.data);
                setTimeout(() => {
                  setIsLoading(false);
                }, 2000);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Videocasts | Cardio4All</title>
        <meta name="description" content="Vídeos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Vídeos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Vídeos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:description" content="Vídeos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BannerVideoImage}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row>
        <Col span={24}>
          <BannerVideocast data={videos[0]} />
          <ListVideocasts data={videos} dataPodcasts={podcasts} dataPraticalGuides={guides} />
        </Col>
      </Row>
    </Spin>
  );
}
