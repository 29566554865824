import React, { useState, useEffect } from "react";
import { Col, Row, Select, Button, Modal, Card, Upload, Empty, message, Spin, Pagination, Popover, Tooltip } from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { EditOutlined, EllipsisOutlined, SettingOutlined, DeleteOutlined, CopyOutlined, EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import Delete from "../../../components/dashboard/article/Delete";
import axios from "axios";
import dayjs from "dayjs";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import "./Articles.css";
import "../../../assets/styles/admin.css";

const { Meta } = Card;
const { confirm } = Modal;

const DashboardPodcasts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [medicalAreas, setMedicalAreas] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.articles.read)
      .then((res) => {
        handlePrepareData(res.data[0], res.data[1], res.data[2]);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function handlePrepareData(auxData, auxTags, auxMedicalAreas) {
    let newData = [];
    for (let i = 0; i < auxData.length; i++) {
      let stringTags = [];
      let stringMedicalAreas = [];
      if (auxData[i].tags && auxData[i].tags.length > 0) {
        let tagsOfArticle = JSON.parse(auxData[i].tags);
        for (let z = 0; z < tagsOfArticle.length; z++) {
          let findStringTag = auxTags.filter((item) => item.id === tagsOfArticle[z])[0];
          console.log(findStringTag);
          if (findStringTag) {
            stringTags.push(findStringTag?.name);
          }
        }
      }
      if (auxData[i].medical_areas && auxData[i].medical_areas.length > 0) {
        let medicalAreasOfArticle = JSON.parse(auxData[i].medical_areas);
        for (let y = 0; y < medicalAreasOfArticle.length; y++) {
          let findStringMedicalArea = auxMedicalAreas.filter((item) => item.id === medicalAreasOfArticle[y])[0];
          if (findStringMedicalArea) {
            stringMedicalAreas.push(findStringMedicalArea?.name);
          }
        }
      }

      newData.push({ ...auxData[i], stringTags, stringMedicalAreas });
    }

    setData(newData);
    setTags(auxTags);
    setMedicalAreas(auxMedicalAreas);
    setIsLoading(false);
  }

  function handleDuplicate(item) {
    axios
      .post(endpoints.articles.duplicate, {
        data: item,
      })
      .then((res) => {
        handleGetData();
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    navigate("./create");
  }

  function handleOpenUpdate(data) {
    navigate(`./update/${data.id}`);
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
        <Col span={24}>
          <Row className="title-admin-page d-flex jc-sb ai-center">
            <Col span={12}>
              <h2 className="m-0">Artigos</h2>
            </Col>
            <Col span={12} className="d-flex jc-end ai-center">
              <Button size="large" className="mr-10" onClick={handleGetData}>
                <ReloadOutlined />
              </Button>
              <Button size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </Col>
          </Row>
          <Row gutter={[0, 24]} className="m-0">
            {data.length === 0 && (
              <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
                <Empty description="Não foram encontrados artigos" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}

            {data?.slice(minValue, maxValue).map((item) => {
              return (
                <Col span={24} key={item.id} className="p-0">
                  <Row gutter={[24]} className="dashboard-article-item m-0">
                    <Col span={3}>
                      <p className="f-11 mb-5">Imagem</p>
                      <img src={`${config.server_ip}/assets/media/${item.image_thumbnail}`} className="w-100" />
                    </Col>
                    <Col span={16}>
                      <Row gutter={[24]}>
                        <Col span={12}>
                          <p className="f-11 mb-5">Título</p>
                          <p className="ellipsisThree bold">{item.title}</p>
                        </Col>
                        <Col span={12}>
                          <p className="f-11 mb-5">Descrição</p>
                          <p className="ellipsisThree">{item.description}</p>
                        </Col>
                      </Row>
                      <Row gutter={[24]} className="mt-30">
                        <Col span={12}>
                          <p className="f-11 mb-5">Slug</p>
                          <p className="f-12">/{item.slug}</p>
                        </Col>
                        <Col span={12}>
                          <p className="f-11 mb-5">Tags</p>
                          <div className="d-flex flex-wrap">
                            {item.stringTags?.map((tag) => (
                              <div className="f-14 tag-dashboard">
                                <p>{tag}</p>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Col>
                    <Col span={4}>
                      <p className="f-11 mb-5">Data</p>
                      <p className="f-14">{dayjs(item.date).format("DD-MM-YYYY")}</p>
                      <p className="f-11 mt-30 mb-5">Área Médica</p>
                      <p className="f-14">
                        {item.stringMedicalAreas?.map((tag) => (
                          <div className="f-14 tag-dashboard">
                            <p>{tag}</p>
                          </div>
                        ))}
                      </p>
                    </Col>
                    <Col span={1} className="d-flex flex-column jc-center ai-center">
                      <Tooltip title="Pré-visualizar">
                        <EyeOutlined key="view" className="f-20 mb-20" onClick={() => window.open(`/artigos/${item.slug}`)} />
                      </Tooltip>
                      <Tooltip title="Editar">
                        <EditOutlined key="edit" className="f-20 mb-20" onClick={() => handleOpenUpdate(item)} />
                      </Tooltip>
                      <Tooltip title="Apagar">
                        <DeleteOutlined key="delete" className="f-20 mb-20" onClick={() => handleOpenDelete(item)} />
                      </Tooltip>
                      <Tooltip title="Duplicar">
                        <CopyOutlined key="copy" className="f-20" onClick={() => handleDuplicate(item)} />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              );
            })}

            <Row className="ml-0 mr-0" align="center">
              <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={data.length} />
            </Row>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default DashboardPodcasts;
