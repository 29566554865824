import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, Upload, Input, message, Drawer, DatePicker } from "antd";
import "../../../assets/styles/admin.css";
import api from "../../../services/api";
import { Outlet, Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import Editor from "./../editor";
import { InboxOutlined, FileImageOutlined, DesktopOutlined, MobileOutlined, PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import dayjs from "dayjs";
import CreateTag from "../tag/Create";

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenCreateTag, setIsOpenCreateTag] = useState(false);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [fileListThumbnail, setFileListThumbnail] = useState([]);
  const [tags, setTags] = useState([]);

  const [filePreview, setFilePreview] = useState({});

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const uploadPropsImageBanner = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListBanner.indexOf(file);
      const newFileList = fileListBanner.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.desktop = "";
      newFileList.splice(index, 1);
      setFileListBanner(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "banner");
      setFileListBanner([file]);
      return false;
    },
    fileList: fileListBanner,
    defaultFileList: [],
  };

  const uploadPropsImageThumbnail = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListThumbnail.indexOf(file);
      const newFileList = fileListThumbnail.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.mobile = "";
      newFileList.splice(index, 1);
      setFileListThumbnail(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      const isImage = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml";
      if (!isImage) {
        message.error({
          type: "error",
          content: "Apenas é possível fazer upload de imagens.",
        });
      } else {
        handlePreview(file, "thumbnail");
        setFileListThumbnail([file]);
      }
      return false;
    },
    fileList: fileListThumbnail,
    defaultFileList: [],
  };

  useEffect(() => {
    handleGetTags();
  }, []);

  function handleGetTags() {
    axios
      .get(endpoints.tags.read)
      .then((res) => {
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function slugify(str) {
    return String(str)
      .normalize("NFKD")
      .replace(/[\u0300-\u036f]/g, "")
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  }

  function handleChangeTitle(value) {
    let thisSlug = slugify(value);
    form.setFieldValue("slug", thisSlug);
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    values.tags = values.tags ? JSON.stringify(values.tags) : null;
    values.medical_areas = values.medical_areas ? JSON.stringify(values.medical_areas) : null;
    values.date = dayjs(values.date).format("YYYY-MM-DD");

    const formData = new FormData();

    fileListBanner.forEach((file) => {
      formData.append("file_banner", file);
    });

    fileListThumbnail.forEach((file) => {
      formData.append("file_thumbnail", file);
    });

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.articles.create}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setTimeout(() => {
          message.success({ content: "Artigo criado com sucesso!" });
          navigate("/admin/articles");
          form.resetFields();
          setFileListBanner([]);
          setFileListThumbnail([]);
          setFilePreview({});
          setIsButtonLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function handlePreview(file, type) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[type] = filePreviewAux;
    console.log(newFilePreview);
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChangeBody(editor) {
    let body = form.getFieldValue("body");
    body = editor;
    form.setFieldValue("body", body);
  }

  function handleOpenCreateTag() {
    setIsOpenCreateTag(true);
  }

  function handleCloseCreateTag(data) {
    if (data) {
      let auxTags = tags;
      auxTags.push(data);

      auxTags.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      let tagsForm = form.getFieldValue("tags");
      tagsForm.push(data.id);
      form.setFieldValue("tags", tagsForm);
    }
    setIsOpenCreateTag(false);
  }

  return (
    <Row>
      <CreateTag open={isOpenCreateTag} close={handleCloseCreateTag} />
      <Row className="title-admin-page d-flex jc-sb ai-center">
        <Col>
          <h2 className="m-0">Criar novo artigo</h2>
        </Col>
      </Row>

      <Form form={form} onFinish={handleSubmit} layout="vertical" className="w-100" initialValues={{ tags: [] }}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item label="Banner" name="image_banner">
              <Dragger key="image-banner" {...uploadPropsImageBanner} style={{ marginBottom: "5px", height: "100%" }} fileList={[...fileListBanner]}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">Upload da imagem banner</p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.banner ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.banner && filePreview.banner.includes("base64") ? filePreview.banner : `${config.server_ip}assets/media/${filePreview.banner}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Thumbnail" name="image_thumbnail">
              <Dragger key="image-thumbnail" {...uploadPropsImageThumbnail} style={{ marginBottom: "5px", height: "100%" }} fileList={[...fileListThumbnail]}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">Upload da imagem thumbnail</p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.thumbnail ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.thumbnail && filePreview.thumbnail.includes("base64") ? filePreview.thumbnail : `${config.server_ip}assets/media/${filePreview.thumbnail}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label="Título"
              name="title"
              onChange={(e) => handleChangeTitle(e.target.value)}
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="Título" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Data"
              name="date"
              rules={[
                {
                  type: "date",
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <DatePicker
                size="large"
                showTime
                placeholder="Data"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="Slug" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={[24]}>
              <Col span={22}>
                <Form.Item
                  label="Tags"
                  name="tags"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    size="large"
                    showSearch
                    placeholder="Tags"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                    options={tags.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </Form.Item>
              </Col>
              <Col span={2} className="d-flex jc-end ai-center">
                <PlusCircleOutlined onClick={handleOpenCreateTag} style={{ fontSize: 20 }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Descrição" />
        </Form.Item>
        <Form.Item label="Referências" name="refs">
          <ReactQuill placeholder="Referências" size="large" />
        </Form.Item>
        <Form.Item
          className="body_form_item"
          label="Corpo"
          name="body"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Editor size="large" type="create" onChange={handleChangeBody} />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Button loading={isButtonLoading} type="primary" size="large" onClick={form.submit}>
              Criar artigo
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default Create;
