import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Button,
  Modal,
  Card,
  Upload,
  Empty,
  message,
  Pagination,
} from "antd";
import { Outlet, Link } from "react-router-dom";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Update from "../../../components/dashboard/podcast/Update";
import Create from "../../../components/dashboard/podcast/Create";
import Delete from "../../../components/dashboard/podcast/Delete";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import "../../../assets/styles/admin.css";
import dayjs from "dayjs";

const { Meta } = Card;
const { confirm } = Modal;

const DashboardPodcasts = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  useEffect(() => {
    handleGetBanners();
  }, []);

  function handleGetBanners() {
    axios
      .get(endpoints.podcasts.read)
      .then((res) => {
        console.log(res);
        setPodcasts(res.data[0]);
        setTags(res.data[1]);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetBanners();
  }

  function handleOpenUpdate(data) {
    setSelectedPodcast(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetBanners();
  }

  function handleOpenDelete(data) {
    setSelectedPodcast(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedPodcast({});
    setIsOpenDelete(false);
    handleGetBanners();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} tags={tags} />
      <Update
        open={isOpenUpdate}
        close={handleCloseUpdate}
        data={selectedPodcast}
        tags={tags}
      />
      <Delete
        open={isOpenDelete}
        close={handleCloseDelete}
        data={selectedPodcast}
      />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Podcasts</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {podcasts?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={6} key={item.id}>
                <Card
                  cover={
                    <iframe
                      style={{ borderRadius: "12px" }}
                      src={`${item.link}?utm_source=generator&theme=0`}
                      width="100%"
                      height="152"
                      frameBorder="0"
                      allowfullscreen=""
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                    />
                  }
                  actions={[
                    <EditOutlined
                      key="edit"
                      onClick={() => handleOpenUpdate(item)}
                    />,
                    <DeleteOutlined
                      key="delete"
                      onClick={() => handleOpenDelete(item)}
                    />,
                  ]}
                >
                  <Meta
                    title={item.title}
                    description={dayjs(item.date).format("DD-MM-YYYY")}
                  />
                </Card>
              </Col>
            );
          })}

          <Row className="ml-0 mr-0" align="center">
            <Pagination
              showSizeChanger={false}
              onChange={handleChangePage}
              pageSize={itemsPerPage}
              defaultCurrent={1}
              current={currentPage}
              total={podcasts.length}
            />
          </Row>
          {podcasts.length === 0 && (
            <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
              <Empty
                description="Não foram encontrados banners"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardPodcasts;
