import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet";

const Content = styled.section`  
  margin: auto;  
  padding: 60px 20px;
  max-width: 1250px;

  * { margin: 16px 0; }

  p {
    color: #1C1C1C;
    font: normal normal normal 16px/26px Poppins;
    letter-spacing: 0.48px;
    text-align: left;
  }

  a {
    color: #1C1C1C;
    text-decoration: underline;
  }

  @media (max-width: 990px) {
    padding: 120px 20px;
  }
`

const Title = styled.h2`
  color: #0E426A;
  font: normal normal bold 36px/55px Poppins;
  letter-spacing: 1.08px;
  margin-bottom: 30px;
  text-align: center;
`

const PoliticaPrivacidadePage = () => {
  return (
    <>
      <Helmet>
        <title>Política de privacidade do Cardio4all | Formação Científica em Medicina Cardiovascular</title>
        <meta name="description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
        <meta name="keywords" content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:title" content="Política de privacidade do Cardio4all | Formação Científica em Medicina Cardiovascular" />
        <meta property="og:description" content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares." />
      </Helmet>
      <Content>
        <Title>Política de privacidade</Title>
        <p>
          A sua privacidade é importante para nós. É política do Cardio4all respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site <a href="https://cardio4all.pt/">https://cardio4all.pt/</a> ou na sua respectiva APP, e outros sites que possuímos e operamos. 
        </p>
        <p>
          Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado. 
        </p>
        <p>
          Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. 
        </p>
        <p>
          Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei. 
        </p>
        <p>
          O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade. 
        </p>
        <p>
          Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados. 
        </p>
        <p>
          O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco. 
        </p>
        <p>
          <b>
            Esta política é efetiva a partir de Setembro/2023.
          </b>
        </p>
      </Content>
    </>
  );
};
export default PoliticaPrivacidadePage