import React, { useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerGuiasPraticos from "../../../components/website/BannerGuiasPraticos/BannerGuiasPraticos";
import ListGuiasPraticos from "../../../components/website/ListGuiasPraticos/ListGuiasPraticos";
import { Button, Col, Row, Spin, Statistic, Image, Form, Radio, Input, Checkbox, notification } from "antd";
import { Helmet } from "react-helmet";
import BgOrientations from "../../../assets/images/banner-orientacao@2x.png";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import BialImage from "../../../assets/images/BIAL.png";
import "./ClinicalCases.css";
import config from "../../../services/config";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ReactPlayer from "react-player";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Countdown } = Statistic;
const ClinicalCasesOrientation = () => {
  const [data, setData] = useState({});
  const [calculators, setCalculators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const { slug } = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.clinicalCases.readOrientation, {
        params: { slug },
      })
      .then((res) => {
        console.log(res);
        let auxClinicalCase = res.data[0][0];
        if (auxClinicalCase && auxClinicalCase.orientation_data) {
          auxClinicalCase.orientation_data = JSON.parse(auxClinicalCase.orientation_data);
        }
        console.log(auxClinicalCase);
        setData(auxClinicalCase);
        setCalculators(res.data[1]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmitFeedback(values) {
    setIsButtonLoading(true);
    console.log(values);
    delete values.checked;

    axios
      .post(endpoints.clinicalCases.createFeedback, {
        data: values,
        clinicalCase: data,
      })
      .then((res) => {
        setIsButtonLoading(false);
        notification.success({
          message: "Feedback enviado!",
          description: "O seu feedback foi enviado com sucesso!",
        });
        console.log(res);
      })
      .catch((err) => {
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "O seu feedback não foi enviado. Por favor, tente novamante. Se o problema persisitir, por favor contacte-nos",
        });
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular</title>
        <meta name="description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Guias Práticos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BgOrientations}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <BannerPrincipal
        color={"#F5F5F5"}
        backgroundImage={BgOrientations}
        backText={"< Voltar a Casos Clínicos"}
        backLink={"/casos-clinicos/" + data.slug}
        children={
          <>
            <p className="f-40 bold blue">Orientação caso clínico</p>
            <p className="f-24 mt-10" style={{ color: "#F04C4B" }}>
              {data?.name}
            </p>
          </>
        }
      />

      <Row>
        <Swiper autoHeight={true} modules={[Pagination, Navigation]} navigation={true} className="mySwiper w-100 d-flex jc-start ai-start swiper-clinical">
          {data.orientation_data &&
            Object.keys(data.orientation_data)?.map((key, i) => (
              <SwiperSlide key={"swiper-card-" + key} id={key} className="swiper-card">
                {data.orientation_data[key].map((item, index) => {
                  console.log(item);
                  if (item.type === "icones") {
                    console.log(item.icones.filter((icone) => icone.span));
                  }
                  return (
                    <div
                      style={{
                        background: item.background ? item.background : "transparent",
                      }}
                    >
                      <div className="d-flex flex-column jc-center ai-center container">
                        {item.type === "image" && (
                          <div className="d-flex jc-center ai-center">
                            {windowWidth > 600 ? (
                              <img className="w-100 mt-20 mb-20" src={`${config.server_ip}/assets/media/${item.image}`} />
                            ) : (
                              <img className="w-100 mt-20 mb-20" src={`${config.server_ip}/assets/media/${item.image_mobile}`} />
                            )}
                          </div>
                        )}
                        {item.type === "references" && (
                          <div className="d-flex jc-center ai-center">
                            <div className="references mt-20 mb-20" dangerouslySetInnerHTML={{ __html: item.references }}></div>
                          </div>
                        )}
                        {item.type === "text" && (
                          <div className="w-100">
                            <div className="d-flex ai-center w-100">
                              <div className="text mt-20 mb-20 blue" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                            </div>
                          </div>
                        )}
                        {item.type === "video" && (
                          <div className="d-flex flex-column jc-center ai-center">
                            <p className="mt-0 f-30 text-center">
                              Veja também o vídeo sobre como usar a calculadora, explicado pelo <br />
                              <b>Prof. Doutor Ricardo Fontes de Carvalho</b>
                            </p>
                            <div className="d-flex flex-column mt-20 mb-20 w-100" style={{ minHeight: 200 }}>
                              <div className="player-wrapper">
                                <ReactPlayer className="react-player" controls volume={1} url={item?.link} />
                              </div>
                            </div>
                          </div>
                        )}
                        {item.type === "calculator" && (
                          <div className="d-flex jc-center ai-center mt-20 mb-20">
                            <Link target="_blank" to={calculators.filter((calc) => calc.id === item.calculator)[0]?.slug}>
                              <Button size="large" type="primary">
                                {calculators.filter((calc) => calc.id === item.calculator)[0]?.name}
                              </Button>
                            </Link>
                          </div>
                        )}

                        {item.type === "cta_image" && (
                          <div className="w-100 mt-20">
                            <Row gutter={[24]}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <img
                                  className="w-100"
                                  src={`${config.server_ip}/assets/media/${item.image}`}
                                  style={{ maxWidth: item.image_max_width ? `${item.image_max_width}px` : "auto" }}
                                />
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="blue lh-24px" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                <Link to={item.button_link} target="_blank">
                                  <Button danger type="primary" size="large" className="mt-20">
                                    {item.button_text}
                                  </Button>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {item.type === "icones" && (
                          <div className="w-100 mt-20">
                            <p className="bold blue f-20">{item.title}</p>
                            {item.icones.filter((icone) => icone.span) && item.icones.filter((icone) => icone.span).length > 0 ? (
                              <Row gutter={[24, 36]} className="d-flex jc-center mt-20 w-100">
                                {item.icones.map((icone) => (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={icone.span ? icone.span : 6}
                                    lg={icone.span ? icone.span : 6}
                                    xl={icone.span ? icone.span : 6}
                                    className={`d-flex ai-start jc-center`}
                                  >
                                    <div className={`d-flex ${item.layout === "vertical" ? "flex-column" : "flex-row"} ai-center jc-start`}>
                                      <img
                                        className="w-100"
                                        src={`${config.server_ip}/assets/media/${icone.image}`}
                                        style={{ maxWidth: icone.image_max_width ? `${icone.image_max_width}px` : "auto" }}
                                      />
                                      <div className="blue text-center" dangerouslySetInnerHTML={{ __html: icone.text }}></div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              <div
                                className={`grid-icones`}
                                style={{
                                  gridTemplateColumns: `repeat(${item.icones.length}, 1fr)`,
                                }}
                              >
                                {item.icones.map((icone) => (
                                  <div className={`d-flex ${item.layout === "vertical" ? "flex-column" : "flex-row"} ai-center jc-start p-10`}>
                                    <img
                                      className="w-100"
                                      src={`${config.server_ip}/assets/media/${icone.image}`}
                                      style={{ maxWidth: icone.image_max_width ? `${icone.image_max_width}px` : "auto" }}
                                    />
                                    <div className={`blue ${icone.text_align ? `text-${icone.text_align}` : "text-center"}`} dangerouslySetInnerHTML={{ __html: icone.text }}></div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </SwiperSlide>
            ))}
        </Swiper>
      </Row>

      <div className="container">
        <p className="f-20 bold blue mt-40 mb-10">Mensagens-chave</p>
        {data.key_messages &&
          JSON.parse(data.key_messages).map((item, index) => (
            <div className="key_message_item">
              <Row gutter={[24]} className="mb-20 d-flex jc-center ai-center">
                <Col xs={24} sm={24} md={2} lg={1} xl={1} span={1} className="mt-10">
                  <div className="number">{index + 1}</div>
                </Col>
                <Col xs={24} sm={24} md={20} lg={23} xl={23} className="mt-10">
                  <div className="blue" dangerouslySetInnerHTML={{ __html: item.text }} />
                </Col>
              </Row>
            </div>
          ))}
      </div>

      <div style={{ backgroundColor: "#F6F6F6" }}>
        <div className="container">
          <p className="f-20 bold blue mt-40 mb-10">Feedback</p>
          <p className="blue">
            A sua opinião é importante para nós! Preencha o formulário caso tenha alguma dúvida, ou envie-nos um comentário e ajude-nos a melhorar os nossos casos clínicos.
          </p>
          <Form form={form} onFinish={handleSubmitFeedback}>
            <Row gutter={[24]} className="mt-20">
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input size="large" placeholder="*Nome" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input size="large" placeholder="*Apelido" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="job"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input size="large" placeholder="*Cargo" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input size="large" placeholder="*Assunto" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={10000} size="large" placeholder="*Mensagem" style={{ minHeight: 200 }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="checked"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error("Este campo é obrigatório"))),
                    },
                  ]}
                >
                  <Checkbox size="large">
                    <p className="blue">*Concordo com os Termos e Condições e Política de Privacidade.</p>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <p className="f-12 blue">*Preenchimento obrigatório</p>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} align="end">
                <Button loading={isButtonLoading} size="large" type="primary" danger onClick={form.submit}>
                  Enviar
                </Button>
              </Col>
            </Row>
            <Row gutter={[24]}></Row>
          </Form>
        </div>
      </div>

      <Row span={24} style={{ backgroundColor: "#FFF" }}>
        <Col span={24} align="middle" className="container pt-40 pb-40">
          <p className="blue">Com o patrocínio independente de:</p>
          <img src={BialImage} className="w-100 maxw-160" />
          <p className="f-12 blue italic">O patrocinador destes conteúdos Cardio4all não tem qualquer influência na criação dos mesmos</p>
        </Col>
      </Row>
    </Spin>
  );
};

export default ClinicalCasesOrientation;
