import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyOutlined, DeleteOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import { message, Upload, Row, Col, Image, Button, Card, Pagination } from "antd";
import axios from "axios";
import "./Media.css";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "../../../assets/styles/admin.css";
import Meta from "antd/es/card/Meta";
import Delete from "../../../components/dashboard/media/Delete";

const { Dragger } = Upload;

function Media() {
  const [selectedMedia, setSelectedMedia] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [media, setMedia] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(30);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetMedia();
  }, []);

  function handleGetMedia() {
    axios
      .get(endpoints.media.read)
      .then((res) => {
        setMedia(res.data);
        setIsButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUploadMedia() {
    setIsButtonLoading(true);
    let formData = new FormData();

    fileList.forEach((file) => {
      formData.append("file", file);
    });

    fetch(`${config.server_ip}${endpoints.media.upload}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        message.success({ content: resData.message });
        setFileList([]);
        handleGetMedia();
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  const uploadProps = {
    accept: "image/*",
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let aux = fileList;
      aux.push(file);
      setFileList(aux);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  function handleCopyClipboard(link) {
    navigator.clipboard.writeText(`/assets/media/${link}`);
    message.success({ content: "Link da imagem copiada" });
  }

  function handleOpenDelete(item) {
    setSelectedMedia(item);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    handleGetMedia();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedMedia} />
      <Col span={24} className="dashboard_card">
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Multimédia</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
            </Dragger>
            <Button loading={isButtonLoading} onClick={handleUploadMedia} className="mt-20" type="primary">
              Upload
            </Button>
          </Col>
        </Row>
        <Row className="media_row mt-40" gutter={[24, 24]}>
          {media?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={4} key={item.id}>
                <Card
                  cover={<img alt="example" src={`${config.server_ip}/assets/media/${item.name}`} />}
                  actions={[<CopyOutlined key="edit" onClick={() => handleCopyClipboard(item.name)} />, <DeleteOutlined key="delete" onClick={() => handleOpenDelete(item)} />]}
                >
                  <Meta title={<p className="normal f-12">{item.name}</p>} />
                </Card>
              </Col>
            );
          })}

          <Row className="ml-0 mr-0" align="center">
            <Pagination showSizeChanger={false} onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={media.length} />
          </Row>
        </Row>
      </Col>
    </Row>
  );
}

export default Media;
