import React, { useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerGuiasPraticos from "../../../components/website/BannerGuiasPraticos/BannerGuiasPraticos";
import ListGuiasPraticos from "../../../components/website/ListGuiasPraticos/ListGuiasPraticos";
import { Button, Col, Divider, Popover, Row, Spin } from "antd";
import { Helmet } from "react-helmet";
import BgGuiasPraticos from "../../../assets/images/guias-praticosk-bg.png";
import { Link, useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import CalculatorCTA from "../../../components/website/CalculatorCTA/CalculatorCTA";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import "./PracticalGuideTopic.css";
import config from "../../../services/config";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

const PracticalGuideTopic = () => {
  const [guide, setGuide] = useState({});
  const [modules, setModules] = useState([]);
  const [topic, setTopic] = useState(null);
  const [previousTopic, setPreviousTopic] = useState({});
  const [nextTopic, setNextTopic] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [widthSize, setWidthSize] = useState(window.innerWidth);

  const { slug } = useParams();
  const { topic_slug } = useParams();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidthSize(window.innerWidth);
  };

  useEffect(() => {
    handleGetGuide();
  }, [slug, topic_slug]);

  function handleGetGuide() {
    setIsLoading(true);
    axios
      .get(endpoints.guides.readBySlug, {
        params: { slug, topic_slug },
      })
      .then((res) => {
        console.log(res);
        let auxModules = res.data[1];
        let auxTopics = res.data[2];
        let newModules = [];

        for (let i = 0; i < auxModules.length; i++) {
          newModules.push({
            ...auxModules[i],
            topics: auxTopics.filter((item) => item.id_guide_module === auxModules[i].id),
          });
        }

        let singleTopic = auxTopics.filter((item) => item.slug === topic_slug)[0];

        if (singleTopic) {
          let auxModule = newModules.filter((item) => item.id === singleTopic.id_guide_module)[0];

          let modulesTopics = auxModule.topics;

          let nextTopicSameModule = modulesTopics.filter((item) => item.position === singleTopic.position + 1);

          //Próximo tópico mas mesmo módulo
          if (nextTopicSameModule.length > 0) {
            console.log("Próximo tópico mas mesmo módulo");
            setNextTopic({
              slug: `/guias-praticos/${slug}/${nextTopicSameModule[0].slug}`,
            });
          }
          //Próximo tópico mas próximo módulo
          else {
            let nextModule = newModules.filter((item) => item.position === auxModule.position + 1);

            if (nextModule.length > 0) {
              setNextTopic({
                slug: `/guias-praticos/${slug}/${nextModule[0].topics[0]?.slug}`,
              });
            } else {
              setNextTopic({ slug: null });
            }
          }

          let previousTopicSameModule = modulesTopics.filter((item) => item.position === singleTopic.position - 1);

          //Tópico anterior mas mesmo módulo
          if (previousTopicSameModule.length > 0) {
            setPreviousTopic({
              slug: `/guias-praticos/${slug}/${previousTopicSameModule[0].slug}`,
            });
          }
          //Tópico anterior mas módulo anterior
          else {
            let previousModule = newModules.filter((item) => item.position === auxModule.position - 1);

            if (previousModule.length > 0) {
              let topicsPreviousModule = previousModule[0].topics;
              console.log(topicsPreviousModule[topicsPreviousModule.length - 1]?.slug);
              setPreviousTopic({
                slug: `/guias-praticos/${slug}/${topicsPreviousModule[topicsPreviousModule.length - 1]?.slug}`,
              });
            } else {
              setPreviousTopic({ slug: `/guias-praticos/${slug}` });
            }
          }
          console.log(singleTopic);
          setTopic(singleTopic);
        } else {
          setTopic(null);
        }

        setGuide(res.data[0][0]);
        setModules(newModules);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>
          {topic?.title} | {guide.title}
        </title>
        <meta name="description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Guias Práticos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BgGuiasPraticos}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row className="practical_guide_topic">
        <Col span={24}>
          <BannerPrincipal
            color={"rgb(240, 76, 75)"}
            backgroundImage={guide?.image}
            children={
              <>
                <p className="f-20" style={{ color: "#F8A6A5" }}>
                  Guia Prático
                </p>
                <p className="white f-26 bold mt-10 ellipsisThree">{guide?.title}</p>
              </>
            }
          />
          <Row style={{ backgroundColor: "#E1EDF8" }}>
            <Row className="container">
              <Col span={24}>
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={30}
                  grabCursor={true}
                  navigation={{
                    enabled: widthSize < 675 ? true : false,
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled",
                  }}
                  modules={[Navigation]}
                  className="swiper_guide_modules d-flex jc-start ai-start"
                >
                  {modules?.map((item) => (
                    <SwiperSlide>
                      <Popover
                        placement={widthSize < 675 ? "bottom" : "bottomLeft"}
                        overlayStyle={{
                          width: widthSize < 675 ? "90vw" : "auto",
                        }}
                        trigger={"click"}
                        content={
                          <Row>
                            <Col span={24} className="d-flex flex-column">
                              {item.topics.map((t) => (
                                <Link to={`/guias-praticos/${slug}/${t.slug}`} className="d-flex ai-center jc-sb">
                                  <p className="blue mt-10 mb-10 f-14">
                                    {item.position}.{t.position} {t.title}
                                  </p>
                                  <span className="ml-20">
                                    <ArrowRightOutlined className="blue" />
                                  </span>
                                </Link>
                              ))}
                            </Col>
                          </Row>
                        }
                      >
                        <Button value={item.id} className={item.id === topic.id_guide_module ? "button_module_topics selected" : "button_module_topics"}>
                          <div className="d-flex jc-center ai-center">
                            <span className="f-26 bold mr-10">{item.position < 9 ? `0${item.position}` : item.position}</span>
                            <p className="f-16">{item.title}</p>
                          </div>
                        </Button>
                      </Popover>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {widthSize < 675 && (
                  <Row className="d-flex jc-sb w-100">
                    <Col span={12}>
                      <div className="image-swiper-button-prev">
                        <div className="d-flex flex-column ai-center">
                          <ArrowLeftOutlined style={{ color: "rgb(133, 184, 227)" }} />
                          <p className="mt-10 mb-0" style={{ color: "rgb(133, 184, 227)" }}>
                            anterior
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="image-swiper-button-next">
                        <div className="d-flex flex-column ai-center">
                          <ArrowRightOutlined style={{ color: "rgb(133, 184, 227)" }} />
                          <p className="mt-10 mb-0" style={{ color: "rgb(133, 184, 227)" }}>
                            próximo
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Row>

          <Row className="container mt-20">
            <Link to={`/guias-praticos/${slug}`} className="d-flex flex-column">
              <p style={{ color: "#85B8E3" }} className="f-16 bold">
                Voltar a guias
              </p>
              <span className="d-flex ai-center jc-center mt-10">
                <ArrowLeftOutlined style={{ color: "#85B8E3" }} />
              </span>
            </Link>
          </Row>
          {topic ? (
            <>
              <Row className="container">
                <Col span={24}>
                  <p className="blue f-30 topic_title">
                    <b>
                      {modules.filter((item) => item.id === topic.id_guide_module)[0]?.position}.{topic?.position}
                    </b>{" "}
                    | {topic.title}
                  </p>
                </Col>
              </Row>
              {widthSize > 700 ? (
                topic?.image_desktop ? (
                  <Row className="container topic_content">
                    <Col span={24}>
                      <img className="w-100" src={`${config.server_ip}/assets/media/${topic?.image_desktop}`} />
                      {topic?.message && (
                        <Row>
                          <Col span={24} className="tips_container mt-100">
                            <p className="f-26 blue bold">Dicas Úteis</p>
                            <div
                              className="mt-20 blue"
                              dangerouslySetInnerHTML={{
                                __html: topic?.message,
                              }}
                            ></div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row className="container topic_content">
                    <Col span={24}>
                      <p className="f-24 italic text-center">Estamos em construção deste tópico</p>
                    </Col>
                  </Row>
                )
              ) : (
                <Row className="container topic_content">
                  <Col span={24}>
                    <img className="w-100" src={`${config.server_ip}/assets/media/${topic?.image_mobile}`} />
                    {topic?.message && (
                      <Row>
                        <Col span={24} className="tips_container mt-100">
                          <p className="f-26 blue bold">Dicas Úteis</p>
                          <div className="mt-20 blue" dangerouslySetInnerHTML={{ __html: topic?.message }}></div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              )}
              {topic?.video && (
                <Row className="mt-50" style={{ backgroundColor: "#E1EDF8" }}>
                  <Row className="container">
                    <Col span={24} className="pt-60 pb-60">
                      <ReactPlayer width={"100%"} height={widthSize < 600 ? "200px" : "500px"} controls volume={1} url={topic.video} />
                    </Col>
                  </Row>
                </Row>
              )}
              <Row className="container d-flex jc-sb mt-100">
                <Link to={`${previousTopic.slug}`}>
                  <div className="d-flex flex-column jc-center">
                    <p className="f-16 bold" style={{ color: "#CDCED0" }}>
                      tópico anterior
                    </p>
                    <span className="d-flex jc-center ai-center mt-10">
                      <ArrowLeftOutlined style={{ color: "#CDCED0" }} />
                    </span>
                  </div>
                </Link>
                {nextTopic.slug && (
                  <Link to={`${nextTopic.slug}`}>
                    <div className="d-flex flex-column jc-center">
                      <p className="f-16 blue bold">próximo tópico</p>
                      <span className="d-flex jc-center ai-center mt-10">
                        <ArrowRightOutlined className="blue" />
                      </span>
                    </div>
                  </Link>
                )}
              </Row>
            </>
          ) : (
            <Row>
              <Col span={24}>
                <p className="f-24 italic text-center">Este tópico não existe, por favor verifique o link que inseriu!</p>
              </Col>
            </Row>
          )}
          <Row className="container">
            <Divider className="topic_footer_divider" />
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default PracticalGuideTopic;
