import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Divider, DatePicker } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined, MinusCircleOutlined, PlusOutlined, ArrowUpOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import dayjs from "dayjs";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListElectro, setFileListElectro] = useState([]);
  const [fileListStudy, setFileListStudy] = useState([]);
  const [filePreview, setFilePreview] = useState({});

  const [form] = useForm();

  function handleClose() {
    setFileList([]);
    setFilePreview({});
    form.resetFields();
    close();
  }

  const uploadPropsImage = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.image = "";
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  const uploadPropsElectro = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListElectro.indexOf(file);
      const newFileList = fileListElectro.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.electro = "";
      newFileList.splice(index, 1);
      setFileListElectro(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "electro");
      setFileListElectro([file]);
      return false;
    },
    fileList: fileListElectro,
    defaultFileList: [],
  };

  const uploadPropsStudy = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListStudy.indexOf(file);
      const newFileList = fileListStudy.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.study = "";
      newFileList.splice(index, 1);
      setFileListStudy(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "study");
      setFileListStudy([file]);
      return false;
    },
    fileList: fileListStudy,
    defaultFileList: [],
  };

  async function handlePreview(file, type) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[type] = filePreviewAux;
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.publish_date = dayjs(values.publish_date).format("YYYY-MM-DD HH:mm");
    values.patient_info = JSON.stringify(values.patient_info);
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("file", file);
    });

    fileListElectro.forEach((file) => {
      formData.append("file_electro", file);
    });

    fileListStudy.forEach((file) => {
      formData.append("file_study", file);
    });

    formData.append("data", JSON.stringify(values));
    console.log(values);

    fetch(`${config.server_ip}${endpoints.clinicalCases.create}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
          setFileList([]);
          setFileListElectro([]);
          setFileListStudy([]);
          setFilePreview({});
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-guide"
      key={`drawer-create-guide`}
      title="Criar caso clínico"
      width={1000}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Criar
          </Button>
        </>
      }
    >
      <Row gutter={[24]} className="m-0">
        <Col span={24}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Nome do caso clínico"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="Caso clínico 01" />
            </Form.Item>
            <Form.Item label="Tipo" name="type">
              <Input size="large" placeholder="Insuficiência cardíaca" />
            </Form.Item>
            <Row gutter={[12]} className="m-0">
              <Col span={6} className="pl-0">
                <Form.Item
                  label="Posição"
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <InputNumber className="w-100" min={0} size="large" placeholder="Posição" />
                </Form.Item>
              </Col>
              <Col span={18} className="pr-0">
                <Form.Item
                  label="Data de publicação"
                  name="publish_date"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    size="large"
                    placeholder="Data de publicação"
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12]} className="m-0">
              <Col span={12} className="pl-0">
                <Form.Item label="Título" name="title">
                  <Input size="large" placeholder="Homem de 68 anos" />
                </Form.Item>
              </Col>
              <Col span={12} className="pl-0">
                <Form.Item label="Texto" name="text">
                  <Input size="large" placeholder="Reformado desde hà 4 anos" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="image"
              label="Imagem"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Dragger {...uploadPropsImage} className={`dragger ${filePreview.image ? "dragger_hidden" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <DesktopOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.image ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.image && filePreview.image.includes("base64") ? filePreview.image : `${config.server_ip}assets/media/${filePreview.image}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
            <Divider />
            <p className="f-14 mb-20">Info do paciente</p>
            <Form.List
              name="patient_info"
              rules={[
                {
                  validator: async (_, patient_info) => {
                    if (patient_info.length === 0) {
                      return Promise.reject(new Error("Tem que adicionar alguma informação."));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove, move }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div className="position-relative">
                      <Form.Item label="Título" {...restField} name={[name, "title"]} rules={[{ required: true, message: "Este campo é obrigatório" }]}>
                        <Input size="large" placeholder="Título" />
                      </Form.Item>
                      <Form.Item label="Texto" {...restField} name={[name, "text"]} rules={[{ required: true, message: "Este campo é obrigatório" }]}>
                        <ReactQuill placeholder="Texto" size="large" />
                      </Form.Item>
                      <MinusCircleOutlined className="delete-patient-info" onClick={() => remove(name)} />
                      {name > 0 ? <ArrowUpOutlined className="move-patient-info" onClick={() => move(name, name - 1)} /> : null}
                    </div>
                  ))}
                  <Button size="large" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Adicionar info
                  </Button>
                </>
              )}
            </Form.List>

            <Divider />
            <Form.Item label="Descrição" name="description">
              <ReactQuill placeholder="Descrição" size="large" />
            </Form.Item>
            <Divider />
            <Form.Item name="electrocardiogram" label="Electrocardiograma">
              <Dragger {...uploadPropsElectro} className={`dragger ${filePreview.electro ? "dragger_hidden" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <DesktopOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.electro ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.electro && filePreview.electro.includes("base64") ? filePreview.electro : `${config.server_ip}assets/media/${filePreview.electro}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
            <Form.Item label="Legenda Electrocardiograma" name="electrocardiogram_subtitle">
              <Input size="large" placeholder="Legenda..." />
            </Form.Item>
            <Form.Item label="Relatório Electrocardiograma" name="electrocardiogram_report">
              <ReactQuill placeholder="Relatório" size="large" />
            </Form.Item>
            <Divider />
            <Form.Item name="analytical_study" label="Estudo analítico">
              <Dragger {...uploadPropsStudy} className={`dragger ${filePreview.study ? "dragger_hidden" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <DesktopOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${filePreview.study ? "" : "hidden"}`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.study && filePreview.study.includes("base64") ? filePreview.study : `${config.server_ip}assets/media/${filePreview.study}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
            <Form.Item label="Legenda Estudo Analítico" name="analytical_study_subtitle">
              <Input size="large" placeholder="Legenda..." />
            </Form.Item>
            <Divider />
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="Slug" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
}

export default Create;
