import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Divider,
} from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import {
  InboxOutlined,
  UploadOutlined,
  DesktopOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Update({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState({});

  const [form] = useForm();

  const uploadPropsImage = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.image = "";
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    if (open && Object.keys(data).length > 0) {
      let newFilePreview = JSON.parse(JSON.stringify(filePreview));

      if (data.image && typeof data.image !== "object") {
        newFilePreview.image = data.image;
        setFileList([{ uid: "not_new", name: data.image }]);
        data.image = [{ uid: "not_new", name: data.image }];
      }

      setFilePreview(newFilePreview);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleClose() {
    close();
  }

  async function handlePreview(file, type) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[type] = filePreviewAux;
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    const formData = new FormData();

    fileList.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file", file);
      }
      values.image = file.name;
    });

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.guides.update}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
          setFileList([]);
          setFilePreview({});
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-guide"
      key={`drawer-guide-${data.id}`}
      title="Editar guia"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button
            disabled={isButtonLoading}
            className="mr-10"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            loading={isButtonLoading}
            type="primary"
            onClick={form.submit}
          >
            Submeter
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        name="form_edit"
      >
        <Form.Item hidden name="id">
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="image"
          label="Imagem"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Dragger
            {...uploadPropsImage}
            className={`dragger ${filePreview.image ? "dragger_hidden" : ""}`}
          >
            <p className="ant-upload-drag-icon">
              <DesktopOutlined style={{ color: "rgb(103 103 103)" }} />
            </p>
            <p className="ant-upload-text text">
              <b>Upload da imagem</b>
            </p>
            <p className="ant-upload-hint">Faça Drag and drop aqui</p>
            <div
              className={`preview_file ${filePreview.image ? "" : "hidden"}`}
              style={{
                backgroundImage: `url(${
                  filePreview.image && filePreview.image.includes("base64")
                    ? filePreview.image
                    : `${config.server_ip}/assets/media/${filePreview.image}`
                })`,
              }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Posição"
          name="position"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <InputNumber
            className="w-100"
            min={0}
            size="large"
            placeholder="Posição"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Update;
