import { Row } from "antd";
import "../../../assets/styles/calculator.css";

export const CalculatorContent = (props) => {
  return(
    <Row className={`container_fluid ${props.className}`}>
        <Row className='container'>
          <div className='calculator__content'>
            {props.children}
          </div>
        </Row>
    </Row>
  )
}