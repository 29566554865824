import React, { useState, useEffect } from "react";
import { Col, Row, Select, Button, Modal, Card, Upload, Empty, message, Pagination, Table, Input, Form } from "antd";
import { Outlet, Link } from "react-router-dom";
import { EditOutlined, EllipsisOutlined, SettingOutlined, DeleteOutlined, DownloadOutlined, BackwardOutlined, RollbackOutlined, ReloadOutlined } from "@ant-design/icons";
import Update from "../../../components/dashboard/user/Update";
import Create from "../../../components/dashboard/user/Create";
import Import from "../../../components/dashboard/user/Import";
import Delete from "../../../components/dashboard/user/Delete";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import "../../../assets/styles/admin.css";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import DrawerExportTable from "../../../components/dashboard/Export/DrawerExportTable";

const { Meta } = Card;
const { confirm } = Modal;

const DashboardEventsUsers = () => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Tipo",
      dataIndex: "type",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetUsers();
  }, []);

  function handleGetUsers() {
    setIsLoading(true);
    axios
      .get(endpoints.events.readByParams, {
        params: { type: "VIRTUAL" },
      })
      .then((res) => {
        console.log(res);
        setUsers(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        name: data[i].name,
        email: data[i].email,
        type: data[i].type,
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetUsers();
  }

  function handleOpenUpdate(data) {
    setSelectedUser(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetUsers();
  }

  function handleOpenDelete(data) {
    setSelectedUser(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedUser({});
    setIsOpenDelete(false);
    handleGetUsers();
  }

  function handleOpenImport() {
    setIsOpenImport(true);
  }

  function handleCloseImport() {
    setIsOpenImport(false);
    handleGetUsers();
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = users.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData);
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  return (
    <Row>
      <Import open={isOpenImport} close={handleCloseImport} />
      <DrawerExportTable open={isOpenExportDrawer} close={handleCloseExport} data={tableData} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={6}>
            <h2 className="m-0">Participantes</h2>
          </Col>
          <Col span={18}>
            <div className="d-flex jc-end ai-center">
              <Form onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                <Form.Item name="search" className="mb-0 mr-10">
                  <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Procurar..." />
                </Form.Item>
              </Form>
              <Button className="mr-10" size="large" onClick={handleGetUsers}>
                <ReloadOutlined />
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenExport}>
                Exportar
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenImport}>
                Importar
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table loading={isLoading} columns={columns} dataSource={tableData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardEventsUsers;
