import React, { useEffect, useState } from "react";
import BannerPodcasts from "../../../components/website/BannerPodcasts/BannerPodcasts";
import ListPodcasts from "../../../components/website/ListPodcasts/ListPodcasts";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import { Helmet } from "react-helmet";

import PodcastsBg from "../../../assets/images/banner-podcast.png";

const PodcastsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const storage_token = localStorage.getItem("token");

  useEffect(() => {
    handleGetPodcasts();
  }, []);

  function handleGetPodcasts() {
    axios
      .get(endpoints.podcasts.read)
      .then((res) => {
        setData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Helmet>
        <title>
          Podcasts do Cardio4all de a atualização científica e com as últimas
          guidelines de diagnóstico e tratamento da doença cardiovascular
        </title>
        <meta
          name="description"
          content="Podcasts do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna."
        />
        <meta
          name="keywords"
          content="Podcasts do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Podcasts do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:description"
          content="Podcasts do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna."
        />
        <meta property="og:image" content={`${PodcastsBg}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BannerPodcasts />
      <ListPodcasts data={data} />
    </>
  );
};

export default PodcastsPage;
