import React, { useState } from "react";
import "../../../assets/styles/calculator.css";
import { Col, Form, Row, Space, Input, Select, Button } from "antd";
import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";
import { Helmet } from "react-helmet";

const { Option } = Select;

/* CALCULADORA SELEÇÃO DA TERAPÊUTICA DA DISLIPIDEMIA */
const CalculatorDislipidemiaPage = () => {
  var riskDISP = ["BAIXO RISCO", "RISCO MODERADO", "ALTO RISCO", "MUITO ALTO RISCO"];

  const [resultMessage, setResultMessage] = useState("");
  const [form] = Form.useForm();

  const handleValuesChange = (changedValues, allValues) => {
    let calculatorObject = { ...allValues };
    if (calculatorObject["ldl"] == null || calculatorObject["risk"] == null) {
      setResultMessage("");
      return;
    }

    let messageDISP = "";
    let percentageMessage = "";
    let pointsDISP = "";

    if (calculatorObject.risk !== null && calculatorObject.ldl !== null) {
      calculatorObject.ldl = Number(calculatorObject.ldl);
      switch (true) {
        case calculatorObject.ldl < 55:
          messageDISP = "Modificação estilo de vida";
          break;
        case calculatorObject.ldl >= 55 && calculatorObject.ldl < 70:
          messageDISP = "Modificação estilo de vida";
          if (calculatorObject.risk === "MUITO ALTO RISCO") {
            messageDISP = "Modificação estilo de vida e considerar tratamento (se não controlar)";
          }
          break;
        case calculatorObject.ldl >= 70 && calculatorObject.ldl < 100:
          messageDISP = "Modificação estilo de vida";
          if (riskDISP[calculatorObject.risk] === "ALTO RISCO") {
            messageDISP = "Modificação estilo de vida e considerar tratamento (se não controlar)";
          }
          if (riskDISP[calculatorObject.risk] === "MUITO ALTO RISCO") {
            messageDISP = "Tratamento farmacológico (+modificação estilo vida)";
          }
          break;
        case calculatorObject.ldl >= 100 && calculatorObject.ldl < 116:
          messageDISP = "Modificação estilo de vida";
          if (riskDISP[calculatorObject.risk] === "RISCO MODERADO") {
            messageDISP = "Modificação estilo de vida e considerar tratamento (se não controlar)";
          }
          if (riskDISP[calculatorObject.risk] === "ALTO RISCO" || riskDISP[calculatorObject.risk] === "MUITO ALTO RISCO") {
            messageDISP = "Tratamento farmacológico (+modificação estilo vida)";
          }
          break;
        case calculatorObject.ldl >= 116 && calculatorObject.ldl < 190:
          messageDISP = "Modificação estilo de vida e considerar tratamento (se não controlar)";
          if (riskDISP[calculatorObject.risk] === "ALTO RISCO" || riskDISP[calculatorObject.risk] === "MUITO ALTO RISCO") {
            messageDISP = "Tratamento farmacológico (+modificação estilo vida)";
          }
          break;
        case calculatorObject.ldl >= 190:
          messageDISP = "Tratamento farmacológico (+modificação estilo vida)";
          break;
        default:
          break;
      }
      pointsDISP = "<h3 className='f-30 bold'>" + messageDISP + "</h3>";
      if (messageDISP === "Tratamento farmacológico (+modificação estilo vida)") {
        let targetLDL = 0;
        switch (riskDISP[calculatorObject.risk]) {
          case "MUITO ALTO RISCO":
            targetLDL = 55;
            break;
          case "ALTO RISCO":
            targetLDL = 70;
            break;
          case "RISCO MODERADO":
            targetLDL = 100;
            break;
          default:
            break;
        }

        let percentage = Math.floor(((calculatorObject.ldl - targetLDL) / calculatorObject.ldl) * 100);
        if (percentage > 65) {
          percentageMessage = `O doente precisa de uma redução de ${percentage}% de LDL, sendo geralmente necessário Estatina alta intensidade + Ezetimibe`;
        } else {
          percentageMessage = `O doente precisa de uma redução de ${percentage}% de LDL, estando recomendado iniciar estatina. Se não atingir o valor alvo de LDL, adicionar ezetimibe`;
        }

        pointsDISP += "<p className='f-16 mt-10'>" + percentageMessage + "</p>";
      }

      setResultMessage(pointsDISP);
    }
  };

  return (
    <>
      <Helmet>
        <title>Calculadora SELEÇÃO DA TERAPÊUTICA DA DISLIPIDEMIA | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora SELEÇÃO DA TERAPÊUTICA DA DISLIPIDEMIA | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="SELEÇÃO DA TERAPÊUTICA DA DISLIPIDEMIA" />

      <CalculatorContent className="mt-10">
        <Form className="form_calculator" form={form} onValuesChange={handleValuesChange}>
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 20, md: 20, lg: 60 },
              { xs: 40, sm: 20, md: 20, lg: 60 },
            ]}
          >
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="blue bold">Risco CV Global</p>
              <Row className="mt-10 d-flex flex-nowrap mobile-flex-wrap">
                <Form.Item name={"risk"} className="w-100">
                  <Select placeholder="Selecione uma opção" className="w-100">
                    <Option value={0}>Baixo Risco</Option>
                    <Option value={1}>Risco Moderado</Option>
                    <Option value={2}>Alto Risco</Option>
                    <Option value={3}>Muito Alto Risco</Option>
                  </Select>
                </Form.Item>
                <Button
                  href="/calculadoras/avaliacao-do-risco-cv-global"
                  target="_blank"
                  className="calculator_button text-center d-flex ai-center jc-center ml-30 mobile-w-100 mobile-ml-0"
                >
                  Calcular
                </Button>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <p className="blue bold">LDL (antes do tratamento)</p>
              <Row className="mt-10 d-flex flex-nowrap mobile-flex-wrap">
                <Form.Item name={"ldl"} className="w-100">
                  <Input type="text" className="w-100 text-center" />
                </Form.Item>
                <Button
                  href="/calculadoras/calculo-do-valor-de-ldl"
                  target="_blank"
                  className="calculator_button text-center d-flex ai-center jc-center ml-30 mobile-w-100 mobile-ml-0"
                >
                  Calcular
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {resultMessage && (
        <CalculatorResult>
          <Row className="h-100 ai-center jc-start">
            <Row>
              <p className="f-16 text-uppercase mb-10">Resultado:</p>
            </Row>
            <Row>
              <div dangerouslySetInnerHTML={{ __html: resultMessage }} />
            </Row>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default CalculatorDislipidemiaPage;
