import React, { useState } from "react";
import "../../../assets/styles/calculator.css";
import { Col, Form, Row, Space, Input, Button, Radio, Card, InputNumber, message, Tooltip } from "antd";
import CalculatorBanner from "../../../components/website/CalculatorBanner/CalculatorBanner";
import { CalculatorLinks } from "../../../components/website/CalculatorLinks/CalculatorLinks";
import { CalculatorResult } from "../../../components/website/CalculatorResult/CalculatorResult";
import { CalculatorContent } from "../../../components/website/CalculatorContent/CalculatorContent";
import { Helmet } from "react-helmet";

/* CALCULADORA RISCO CV GLOBAL (IDOSOS) - SCORE2-OP */
const Calculator07Page = () => {
  const [message, setMessage] = useState(null);
  const [errorFinded, setErrorFinded] = useState(null);
  const [minValues] = useState({ age: 70, tas: 100, cholesterol: 116, cholesterolHDL: 27 });
  const [maxValues] = useState({ age: 200, tas: 200, cholesterol: 348, cholesterolHDL: 97 });
  const [percentage, setPercentage] = useState(null);
  const [colorResult, setColorResult] = useState("");
  const [transformedValues, setTransformedValues] = useState({
    sex: null,
    age: null,
    smoke: null,
    tas: null,
    cholesterol: null,
    cholesterolHDL: null,
    smoking_age: null,
    tas_age: null,
    ct_age: null,
    hdl_age: null,
  });
  const [finalValues, setFinalValues] = useState({
    sex: null,
    age: null,
    smoke: null,
    tas: null,
    cholesterol: null,
    cholesterolHDL: null,
    smoking_age: null,
    tas_age: null,
    ct_age: null,
    hdl_age: null,
  });

  const [form] = Form.useForm();

  function handleValidate(rule, value) {
    console.log("VALIDATE");
    let key = rule.field;
    let keysOfNumber = Object.keys(minValues);
    if (keysOfNumber.includes(key)) {
      if (Number(value) < minValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser menor que ${minValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else if (Number(value) > maxValues[rule.field]) {
        return Promise.reject(<Tooltip color="red" placement="bottom" open={true} title={`Este valor não pode ser maior que ${maxValues[rule.field]}`}></Tooltip>); // The validator should always return a promise on both success and error
      } else {
        return Promise.resolve(); // The validator should always return a promise on both success and error
      }
    }
  }

  function handleSubmit() {
    let allValues = form.getFieldsValue();
    let columns = Object.keys(allValues);

    let nullItems = 0;
    let auxErrorsFinded = 0;

    for (let i = 0; i < columns.length; i++) {
      if (typeof allValues[columns[i]] === "string") {
        allValues[columns[i]] = Number(allValues[columns[i]]);
      }

      if (allValues[columns[i]] === null || allValues[columns[i]] === undefined) {
        nullItems++;
      }
    }

    if (nullItems === 0) {
      const validationErrors = Object.values(form.getFieldsError());

      for (let e = 0; e < validationErrors.length; e++) {
        if (validationErrors[e].errors.length > 0) {
          auxErrorsFinded++;
        }
      }
    }

    if (nullItems === 0 && auxErrorsFinded === 0) {
      let auxTransformedValue = transformedValues;
      let auxFinalValues = finalValues;

      auxTransformedValue.sex = allValues.sex;
      auxTransformedValue.age = allValues.age - 73;
      auxTransformedValue.smoke = allValues.smoke;
      auxTransformedValue.tas = allValues.tas - 150;

      auxTransformedValue.cholesterol = allValues.cholesterol * 0.02586;
      auxTransformedValue.cholesterol = auxTransformedValue.cholesterol - 6;

      auxTransformedValue.cholesterolHDL = allValues.cholesterolHDL * 0.02586;
      auxTransformedValue.cholesterolHDL = auxTransformedValue.cholesterolHDL - 1.4;

      if (auxTransformedValue.sex === 1) {
        auxTransformedValue.smoking_age = -0.0247;
        auxTransformedValue.tas_age = -0.0005;
        auxTransformedValue.ct_age = 0.0073;
        auxTransformedValue.hdl_age = 0.0091;

        auxFinalValues.age = 0.0634 * auxTransformedValue.age;
        auxFinalValues.smoke = 0.3524 * auxTransformedValue.smoke;
        auxFinalValues.tas = 0.0094 * auxTransformedValue.tas;
        auxFinalValues.cholesterol = 0.085 * auxTransformedValue.cholesterol;
        auxFinalValues.cholesterolHDL = -0.3564 * auxTransformedValue.cholesterolHDL;
        auxFinalValues.smoking_age = auxTransformedValue.age * auxTransformedValue.smoke * auxTransformedValue.smoking_age;
        auxFinalValues.tas_age = auxTransformedValue.age * auxTransformedValue.tas * auxTransformedValue.tas_age;
        auxFinalValues.ct_age = auxTransformedValue.age * auxTransformedValue.cholesterol * auxTransformedValue.ct_age;
        auxFinalValues.hdl_age = auxTransformedValue.age * auxTransformedValue.cholesterolHDL * auxTransformedValue.hdl_age;

        let sumOfFinalValues =
          auxFinalValues.age +
          auxFinalValues.smoke +
          auxFinalValues.tas +
          auxFinalValues.cholesterol +
          auxFinalValues.cholesterolHDL +
          auxFinalValues.smoking_age +
          auxFinalValues.tas_age +
          auxFinalValues.ct_age +
          auxFinalValues.hdl_age;

        let tenYearsRisk = 1 - Math.pow(0.7576, Math.exp(sumOfFinalValues - 0.0929));
        let calibrationModeratedRisk = 1 - Math.exp(-Math.exp(0.01 + 1.25 * Math.log(-Math.log(1 - tenYearsRisk))));

        let calibrationModeratedRiskPercentage = calibrationModeratedRisk * 100;
        calibrationModeratedRiskPercentage = calibrationModeratedRiskPercentage.toFixed(1);
        calibrationModeratedRiskPercentage = parseFloat(calibrationModeratedRiskPercentage);
        setPercentage(calibrationModeratedRiskPercentage);

        /* SHOW MESSAGE */
        let age = parseInt(allValues.age);

        if (age >= 70) {
          if (calibrationModeratedRiskPercentage < 7.5) {
            setMessage(`Baixo a moderado`);
            setColorResult("yellow");
          } else if (calibrationModeratedRiskPercentage >= 7.5 && calibrationModeratedRiskPercentage < 14.9) {
            setMessage(`Alto`);
            setColorResult("orange");
          } else {
            setMessage(`Muito alto`);
            setColorResult("red");
          }
        } else if (age < 70) {
          setMessage(`CONSULTE A SCORE2`);
        } else {
          setMessage(`ALGUMA COISA SE PASSOU...`);
        }
      } else {
        auxTransformedValue.smoking_age = -0.0255;
        auxTransformedValue.tas_age = -0.0004;
        auxTransformedValue.ct_age = -0.0009;
        auxTransformedValue.hdl_age = 0.0154;

        auxFinalValues.age = 0.0789 * auxTransformedValue.age;
        auxFinalValues.smoke = 0.4921 * auxTransformedValue.smoke;
        auxFinalValues.tas = 0.0102 * auxTransformedValue.tas;
        auxFinalValues.cholesterol = 0.0605 * auxTransformedValue.cholesterol;
        auxFinalValues.cholesterolHDL = -0.304 * auxTransformedValue.cholesterolHDL;
        auxFinalValues.smoking_age = auxTransformedValue.age * auxTransformedValue.smoke * auxTransformedValue.smoking_age;
        auxFinalValues.tas_age = auxTransformedValue.age * auxTransformedValue.tas * auxTransformedValue.tas_age;
        auxFinalValues.ct_age = auxTransformedValue.age * auxTransformedValue.cholesterol * auxTransformedValue.ct_age;
        auxFinalValues.hdl_age = auxTransformedValue.age * auxTransformedValue.cholesterolHDL * auxTransformedValue.hdl_age;

        let sumOfFinalValues =
          auxFinalValues.age +
          auxFinalValues.smoke +
          auxFinalValues.tas +
          auxFinalValues.cholesterol +
          auxFinalValues.cholesterolHDL +
          auxFinalValues.smoking_age +
          auxFinalValues.tas_age +
          auxFinalValues.ct_age +
          auxFinalValues.hdl_age;

        let tenYearsRisk = 1 - Math.pow(0.8082, Math.exp(sumOfFinalValues - 0.229));

        let calibrationModeratedRisk = 1 - Math.exp(-Math.exp(-0.1 + 1.1 * Math.log(-Math.log(1 - tenYearsRisk))));

        let calibrationModeratedRiskPercentage = calibrationModeratedRisk * 100;
        calibrationModeratedRiskPercentage = calibrationModeratedRiskPercentage.toFixed(1);
        calibrationModeratedRiskPercentage = parseFloat(calibrationModeratedRiskPercentage);

        /* SHOW MESSAGE */
        let age = parseInt(allValues.age);

        let calibrationModeratedRiskPercentageString = calibrationModeratedRiskPercentage.toString();
        calibrationModeratedRiskPercentageString = calibrationModeratedRiskPercentageString.replace(".", ",");
        setPercentage(calibrationModeratedRiskPercentage);

        if (age >= 70) {
          if (calibrationModeratedRiskPercentage < 7.5) {
            setMessage(`Baixo a moderado`);
            setColorResult("yellow");
          } else if (calibrationModeratedRiskPercentage >= 7.5 && calibrationModeratedRiskPercentage < 14.9) {
            setMessage(`Alto`);
            setColorResult("orange");
          } else {
            setMessage(`Muito alto`);
            setColorResult("red");
          }
        } else if (age < 70) {
          setMessage(`CONSULTE A SCORE2`);
        } else {
          setMessage(`ALGUMA COISA SE PASSOU...`);
        }
      }

      setErrorFinded(false);
    } else {
      if (auxErrorsFinded > 0) {
        setErrorFinded(true);
      }
      setMessage(null);
    }
  }

  function handleIncrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value >= minValues[key] && value < maxValues[key]) {
        value++;
      } else if (value < minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  function handleDecrement(key) {
    let value = form.getFieldValue(key);
    if (typeof value === "string") {
      value = Number(value);
    }

    if (value) {
      if (value > minValues[key] && value <= maxValues[key]) {
        value--;
      } else if (value <= minValues[key]) {
        value = minValues[key];
      } else {
        value = maxValues[key];
      }

      form.setFieldValue([key], Number(value));
      form.validateFields([key]);
    } else {
      form.setFieldValue([key], minValues[key]);
      form.validateFields([key]);
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora RISCO CV GLOBAL (IDOSOS) - SCORE2-OP | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="calculadoras área cardiovascular, Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Calculadora RISCO CV GLOBAL (IDOSOS) - SCORE2-OP | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
      </Helmet>
      <CalculatorBanner title="RISCO CV GLOBAL (IDOSOS) - SCORE2-OP" />

      <CalculatorContent className="mt-10">
        <Form form={form} onFieldsChange={handleSubmit} className="form_calculator">
          {/* Row #1 */}
          <Row
            gutter={[
              { xs: 20, sm: 8, md: 16, lg: 60 },
              { xs: 40, sm: 8, md: 16, lg: 60 },
            ]}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Sexo</p>
              <Form.Item name={"sex"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={0} className="w-50 mr-10">
                    Feminino
                  </Radio.Button>
                  <Radio.Button value={1} className="w-50 mr-10">
                    Masculino
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Idade</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("age")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"age"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("age")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Valor acima de: 70</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Fumador</p>
              <Form.Item name={"smoke"} className="mt-10 w-100 mb-0">
                <Radio.Group className="w-100 d-flex flex-nowrap">
                  <Radio.Button value={1} className="w-50 mr-10">
                    Sim
                  </Radio.Button>
                  <Radio.Button value={0} className="w-50 ml-10">
                    Não
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            {/* Row #2 */}
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Tensão arterial sistólica (mmHg)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("tas")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"tas"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("tas")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Valor entre: 100 - 200</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Colesterol total (mg/dL)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("cholesterol")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"cholesterol"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("cholesterol")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Valor entre: 116 - 348</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <p className="blue bold">Colesterol HDL (mg/dL)</p>
              <Row className="mt-10 d-flex flex-nowrap">
                <Button onClick={() => handleDecrement("cholesterolHDL")} className="calculator_button mr-10">
                  -
                </Button>
                <Form.Item name={"cholesterolHDL"} className="w-100 mb-0" rules={[{ validator: handleValidate }]}>
                  <Input type="number" className="w-100" />
                </Form.Item>
                <Button onClick={() => handleIncrement("cholesterolHDL")} className="calculator_button ml-10">
                  +
                </Button>
              </Row>
              <p className="f-14 mt-10">Valor entre: 27 - 97</p>
            </Col>
          </Row>
        </Form>
      </CalculatorContent>

      {/* TODO: colocarmos condicao para aparecer o bloco com resultado*/}
      {message && (
        <CalculatorResult>
          <Row className="h-100 ai-start jc-start">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <p>Resultado:</p>
              <div className="d-flex ai-center mt-20">
                <div className={`circle_calc ${colorResult} mr-20`}></div>
                <div className="d-flex flex-column">
                  <p className="f-30 bold text-uppercase">{percentage}%</p>
                  <p className="f-30 bold text-uppercase">{message}</p>
                </div>
              </div>
              <p className="f-12 italic mt-40">% estimada de eventos CV de acordo com o risco global da população portuguesa</p>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className="d-flex flex-column jc-center ai-center mt-40">
              <Row>
                <div className="d-flex ai-center">
                  <div className="circle_calc yellow"></div>
                  <div className="ml-10">
                    <p className="m-0">{"Score < 7,5%:"} grau BAIXO A MODERADO</p>
                  </div>
                </div>
              </Row>
              <Row className="mt-15">
                <div className="d-flex ai-center">
                  <div className="circle_calc orange"></div>
                  <div className="ml-10">
                    <p className="m-0">{"Score 7,5 - 14,9%:"} grau ALTO</p>
                  </div>
                </div>
              </Row>
              <Row className="mt-15">
                <div className="d-flex ai-center">
                  <div className="circle_calc red"></div>
                  <div className="ml-10">
                    <p className="m-0">{"Score ≥ 15%:"} grau MUITO ALTO</p>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      {errorFinded && (
        <CalculatorResult>
          <Row gutter={[24, 24]} className="h-100 ai-center jc-center">
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="d-flex jc-center ai-center">
              <p className="f-20 text-center">Algum valor não está entre os parâmetros requeridos</p>
            </Col>
          </Row>
        </CalculatorResult>
      )}

      <CalculatorLinks />
    </>
  );
};
export default Calculator07Page;
