import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Divider,
} from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import {
  InboxOutlined,
  UploadOutlined,
  DesktopOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function UpdateTopic({ open, close, data, calculators }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [filePreview, setFilePreview] = useState({});

  const [form] = useForm();

  const uploadPropsImageDesktop = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListDesktop.indexOf(file);
      const newFileList = fileListDesktop.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.desktop = "";
      newFileList.splice(index, 1);
      setFileListDesktop(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "desktop");
      setFileListDesktop([file]);
      return false;
    },
    fileList: fileListDesktop,
    defaultFileList: [],
  };

  const uploadPropsImageMobile = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListMobile.indexOf(file);
      const newFileList = fileListMobile.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.mobile = "";
      newFileList.splice(index, 1);
      setFileListMobile(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        message.error({
          type: "error",
          content: "Apenas é possível fazer upload de imagens.",
        });
      } else {
        handlePreview(file, "mobile");
        setFileListMobile([file]);
      }
      return false;
    },
    fileList: fileListMobile,
    defaultFileList: [],
  };

  useEffect(() => {
    if (open && Object.keys(data).length > 0) {
      let newFilePreview = JSON.parse(JSON.stringify(filePreview));

      if (data.image_desktop && typeof data.image_desktop !== "object") {
        newFilePreview.desktop = data.image_desktop;
        setFileListDesktop([{ uid: "not_new", name: data.image_desktop }]);
        data.image_desktop = [{ uid: "not_new", name: data.image_desktop }];
      }

      if (data.image_mobile && typeof data.image_mobile !== "object") {
        newFilePreview.mobile = data.image_mobile;
        setFileListMobile([{ uid: "not_new", name: data.image_mobile }]);
        data.image_mobile = [{ uid: "not_new", name: data.image_mobile }];
      }

      setFilePreview(newFilePreview);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleClose() {
    close();
  }

  async function handlePreview(file, type) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[type] = filePreviewAux;
    console.log(newFilePreview);
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    const formData = new FormData();

    fileListDesktop.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file_desktop", file);
      }
      values.image_desktop = file.name;
    });

    fileListMobile.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file_mobile", file);
      }
      values.image_mobile = file.name;
    });

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.guides.updateTopic}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setTimeout(() => {
          handleClose();
          setIsButtonLoading(false);
          form.resetFields();
          setFileListDesktop([]);
          setFileListMobile([]);
          setFilePreview({});
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-topic"
      key={`drawer-topic-${data.id}`}
      title="Editar tópico"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button
            disabled={isButtonLoading}
            className="mr-10"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            loading={isButtonLoading}
            type="primary"
            onClick={form.submit}
          >
            Submeter
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        name="form_edit"
      >
        <Form.Item hidden name="id">
          <Input size="large" />
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item name="image_desktop" label="Imagem Desktop">
              <Dragger
                {...uploadPropsImageDesktop}
                className={`dragger ${
                  filePreview.desktop ? "dragger_hidden" : ""
                }`}
              >
                <p className="ant-upload-drag-icon">
                  <DesktopOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem em desktop</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${
                    filePreview.desktop ? "" : "hidden"
                  }`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.desktop &&
                      filePreview.desktop.includes("base64")
                        ? filePreview.desktop
                        : `${config.server_ip}/assets/media/${filePreview.desktop}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="image_mobile" label="Imagem Mobile">
              <Dragger
                {...uploadPropsImageMobile}
                className={`dragger ${
                  filePreview.mobile ? "dragger_hidden" : ""
                }`}
              >
                <p className="ant-upload-drag-icon">
                  <MobileOutlined style={{ color: "rgb(103 103 103)" }} />
                </p>
                <p className="ant-upload-text text">
                  <b>Upload da imagem em mobile</b>
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                <div
                  className={`preview_file ${
                    filePreview.mobile ? "" : "hidden"
                  }`}
                  style={{
                    backgroundImage: `url(${
                      filePreview.mobile &&
                      filePreview.mobile.includes("base64")
                        ? filePreview.mobile
                        : `${config.server_ip}/assets/media/${filePreview.mobile}`
                    })`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Dicas úteis" name="message">
          <ReactQuill
            className="w-100"
            size="large"
            placeholder="Dicas úteis"
          />
        </Form.Item>
        <Form.Item label="Video link" name="video">
          <Input size="large" placeholder="Vimeo link" />
        </Form.Item>

        <Form.Item label="Calculadora" name="id_calculator">
          <Select
            size="large"
            showSearch
            placeholder="Calculadoras..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={calculators.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default UpdateTopic;
