import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Upload, Input, message, DatePicker, Spin } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { FileImageOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import Editor from "../editor";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

function Update() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenCreateTag, setIsOpenCreateTag] = useState(false);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [fileListThumbnail, setFileListThumbnail] = useState([]);
  const [tags, setTags] = useState([]);
  const [medicalAreas, setMedicalAreas] = useState([]);

  const [filePreview, setFilePreview] = useState({});

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const uploadPropsImageBanner = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListBanner.indexOf(file);
      const newFileList = fileListBanner.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.desktop = "";
      newFileList.splice(index, 1);
      setFileListBanner(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      handlePreview(file, "banner");
      setFileListBanner([file]);
      return false;
    },
    fileList: fileListBanner,
    defaultFileList: [],
  };

  const uploadPropsImageThumbnail = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileListThumbnail.indexOf(file);
      const newFileList = fileListThumbnail.slice();
      const newFilePreview = JSON.parse(JSON.stringify(filePreview));
      newFilePreview.mobile = "";
      newFileList.splice(index, 1);
      setFileListThumbnail(newFileList);
      setFilePreview(newFilePreview);
    },
    beforeUpload: (file) => {
      const isImage = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml";
      if (!isImage) {
        message.error({
          type: "error",
          content: "Apenas é possível fazer upload de imagens.",
        });
      } else {
        handlePreview(file, "thumbnail");
        setFileListThumbnail([file]);
      }
      return false;
    },
    fileList: fileListThumbnail,
    defaultFileList: [],
  };

  useEffect(() => {
    axios
      .get(endpoints.articles.readById, {
        params: { id: params.id },
      })
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          let data = res.data[0][0];
          setFileListBanner([{ uid: "not_new", name: data.image_banner }]);
          setFileListThumbnail([{ uid: "not_new", name: data.image_thumbnail }]);
          setFilePreview({
            banner: data.image_banner,
            thumbnail: data.image_thumbnail,
          });
          data.image_banner = [{ uid: "not_new", name: data.image_banner }];
          data.image_thumbnail = [{ uid: "not_new", name: data.image_thumbnail }];
          data.body = JSON.parse(data.body);
          data.date = dayjs(data.date);
          data.tags = data.tags ? JSON.parse(data.tags) : null;
          data.medical_areas = data.medical_areas ? JSON.parse(data.medical_areas) : null;

          form.setFieldsValue(data);
        }

        setTags(res.data[1]);
        setMedicalAreas(res.data[2]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.state]);

  function slugify(str) {
    return String(str)
      .normalize("NFKD")
      .replace(/[\u0300-\u036f]/g, "")
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  }

  function handleChangeTitle(value) {
    let thisSlug = slugify(value);
    form.setFieldValue("slug", thisSlug);
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    values.tags = values.tags ? JSON.stringify(values.tags) : null;
    values.medical_areas = values.medical_areas ? JSON.stringify(values.medical_areas) : null;
    values.date = dayjs(values.date).format("YYYY-MM-DD");

    const formData = new FormData();

    fileListBanner.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file_banner", file);
      }
      values.image_banner = file.name;
    });

    fileListThumbnail.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file_thumbnail", file);
      }
      values.image_thumbnail = file.name;
    });

    formData.append("data", JSON.stringify(values));

    console.log(values);

    fetch(`${config.server_ip}${endpoints.articles.update}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setTimeout(() => {
          message.success({ content: "Artigo editado com sucesso!" });
          navigate("/admin/articles");
          form.resetFields();
          setFileListBanner([]);
          setFileListThumbnail([]);
          setFilePreview({});
          setIsButtonLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  async function handlePreview(file, type) {
    let filePreviewAux = await getBase64(file);
    const newFilePreview = JSON.parse(JSON.stringify(filePreview));
    newFilePreview[type] = filePreviewAux;
    console.log(newFilePreview);
    setFilePreview(newFilePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChangeBody(editor) {
    let body = form.getFieldValue("body");
    body = editor;
    form.setFieldValue("body", body);
  }

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Editar artigo</h2>
          </Col>
        </Row>

        <Form form={form} onFinish={handleSubmit} layout="vertical" className="w-100">
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item label="Banner" name="image_banner">
                <Dragger key="image-banner" {...uploadPropsImageBanner} style={{ marginBottom: "5px", height: "100%" }} fileList={[...fileListBanner]}>
                  <p className="ant-upload-drag-icon">
                    <FileImageOutlined style={{ color: "rgb(103 103 103)" }} />
                  </p>
                  <p className="ant-upload-text text">Upload da imagem banner</p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  <div
                    className={`preview_file ${filePreview.banner ? "" : "hidden"}`}
                    style={{
                      backgroundImage: `url(${
                        filePreview.banner && filePreview.banner.includes("base64") ? filePreview.banner : `${config.server_ip}/assets/media/${filePreview.banner}`
                      })`,
                    }}
                  ></div>
                </Dragger>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Thumbnail" name="image_thumbnail">
                <Dragger key="image-thumbnail" {...uploadPropsImageThumbnail} style={{ marginBottom: "5px", height: "100%" }} fileList={[...fileListThumbnail]}>
                  <p className="ant-upload-drag-icon">
                    <FileImageOutlined style={{ color: "rgb(103 103 103)" }} />
                  </p>
                  <p className="ant-upload-text text">Upload da imagem thumbnail</p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  <div
                    className={`preview_file ${filePreview.thumbnail ? "" : "hidden"}`}
                    style={{
                      backgroundImage: `url(${
                        filePreview.thumbnail && filePreview.thumbnail.includes("base64") ? filePreview.thumbnail : `${config.server_ip}/assets/media/${filePreview.thumbnail}`
                      })`,
                    }}
                  ></div>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item
                label="Título"
                name="title"
                onChange={(e) => handleChangeTitle(e.target.value)}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório",
                  },
                ]}
              >
                <Input size="large" placeholder="Título" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Data"
                name="date"
                rules={[
                  {
                    type: "date",
                    required: true,
                    message: "Este campo é obrigatório",
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  showTime
                  placeholder="Data"
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Slug"
            name="slug"
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório",
              },
            ]}
          >
            <Input size="large" placeholder="Slug" />
          </Form.Item>
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item initialValue={null} label="Áreas médicas" name="medical_areas">
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="Áreas médicas"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={medicalAreas.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tags"
                name="tags"
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="Tags"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={tags.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório",
              },
            ]}
          >
            <Input size="large" placeholder="Descrição" />
          </Form.Item>
          <Form.Item label="Referências" name="refs">
            <ReactQuill placeholder="Referências" size="large" />
          </Form.Item>
          <Form.Item
            className="body_form_item"
            label="Corpo"
            name="body"
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório",
              },
            ]}
          >
            <Editor type="update" onChange={handleChangeBody} />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Button loading={isButtonLoading} type="primary" size="large" onClick={form.submit}>
                Editar artigo
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </Spin>
  );
}

export default Update;
