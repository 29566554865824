import React, { useEffect, useState } from "react";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined, PlusCircleOutlined, FileImageOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Rate, Row, Select, Slider, Space, Switch, Upload, Input, Drawer, message, DatePicker } from "antd";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";

import "../../../assets/styles/admin.css";
import api from "../../../services/api";
import endpoints from "../../../services/endpoints";
import CreateTag from "../tag/Create";
import dayjs from "dayjs";
import config from "../../../services/config";
import Dragger from "antd/es/upload/Dragger";

function Update({ open, close, data, tags, guides }) {
  const [previewLink, setPreviewLink] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState(null);

  const [form] = Form.useForm();

  const uploadProps = {
    accept: "image/*",
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      handlePreview(file);
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    if (open && Object.keys(data).length > 0) {
      if (data.image && typeof data.image === "string") {
        setFileList([{ uid: "not_new", name: data.image }]);
        setFilePreview(data.image);
        data.image = [{ uid: "not_new", name: data.image }];
      }
      data.date = dayjs(data.date);
      data.tags = data.tags && typeof data.tags === "string" ? JSON.parse(data.tags) : null;
      setPreviewLink(data.link);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    const formData = new FormData();

    fileList.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file", file);
      } else {
        values.image = file.name;
      }
    });

    values.date = dayjs(values.date).format("YYYY-MM-DD");
    values.tags = values.tags ? JSON.stringify(values.tags) : null;

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.videos.update}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        console.log(resData);
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  function handleClose() {
    close();
    form.resetFields();
    setFileList([]);
    setFilePreview(null);
  }

  function handlePreviewLink(e) {
    if (e.target.value) {
      setPreviewLink(e.target.value);
    }
  }

  async function handlePreview(file) {
    let filePreviewAux = await getBase64(file);
    setFilePreview(filePreviewAux);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      className="drawer-video"
      key={`drawer-create-video`}
      title="Editar video"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Editar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item label="Thumbnail" name="image">
          <Dragger
            key="image-thumbnail"
            {...uploadProps}
            style={{ marginBottom: "5px", height: "100%" }}
            fileList={[...fileList]}
            className={`dragger ${filePreview ? "dragger_hidden" : ""}`}
          >
            <p className="ant-upload-drag-icon">
              <FileImageOutlined style={{ color: "rgb(103 103 103)" }} />
            </p>
            <p className="ant-upload-text text">Upload da imagem thumbnail</p>
            <p className="ant-upload-hint">Faça Drag and drop aqui</p>
            <div
              className={`preview_file ${filePreview ? "" : "hidden"}`}
              style={{
                backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/assets/media/${filePreview}`})`,
              }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="type"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            placeholder="Tipo"
            options={[
              {
                value: "videocasts",
                label: "Videocasts",
              },
              {
                value: "videocasts_scientific_update",
                label: "Videocasts atualização científica",
              },
              {
                value: "guides",
                label: "Guias",
              },
              {
                value: "calculators",
                label: "Calculadoras",
              },
            ]}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) =>
            getFieldValue("type") === "videocasts_scientific_update" ? (
              <Form.Item label="Tipo" name="scientific_type">
                <Select
                  size="large"
                  showSearch
                  placeholder="Tipo"
                  options={[
                    {
                      value: "<i>Guidelines</i> ESC de Diabetes",
                      label: "Guidelines ESC de Diabetes",
                    },
                    {
                      value: "<i>Guidelines</i> Europeias de Insuficiência Cardíaca",
                      label: "Guidelines Europeias de Insuficiência Cardíaca",
                    },
                  ]}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) =>
            getFieldValue("type") === "guides" ? (
              <Form.Item label="Guia" name="id_guide">
                <Select
                  size="large"
                  showSearch
                  placeholder="Guia"
                  options={guides.map((item) => ({
                    value: item.id,
                    label: item.title,
                  }))}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input size="large" placeholder="Título" />
        </Form.Item>
        <Form.Item label="Descrição" name="description">
          <Input size="large" placeholder="Descrição" />
        </Form.Item>
        <Form.Item label="Tags" name="tags">
          <Select
            mode="multiple"
            size="large"
            showSearch
            placeholder="Tags"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={tags.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <Form.Item
          label="Data"
          name="date"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <DatePicker
            size="large"
            placeholder="Data"
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
          />
        </Form.Item>
        <Form.Item label="Texto do botão" name="button_text">
          <Input size="large" placeholder="Exemplo" />
        </Form.Item>
        <Form.Item label="Link do botão" name="button_link">
          <Input size="large" placeholder="/exemplo" />
        </Form.Item>
        <Form.Item label="Link" name="link">
          <Input size="large" placeholder="Link" onChange={handlePreviewLink} />
        </Form.Item>

        {previewLink != "" && (
          <iframe
            width="100%"
            height="300px"
            frameBorder="0"
            src={`${previewLink}`}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        )}
      </Form>
    </Drawer>
  );
}

export default Update;
