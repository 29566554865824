import React, { useEffect, useState } from "react";
import { Button, Form, Input, Drawer, DatePicker, Select, Row, Col, Menu, Modal, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./ModalVideo.css";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import ReactPlayer from "react-player";

function ModalVideo({ data, open, close }) {
  const [play, setPlay] = useState(true);
  const [link, setLink] = useState(null);

  useEffect(() => {
    if (!open) {
      console.log(open);
      setPlay(false);
      setLink(null);
    } else {
      console.log(open);
      setLink(data.link);
    }
  }, [open]);

  function handleClose() {
    setPlay(false);
    setLink(null);
    close();
  }

  return (
    <Modal className="modal-iframe" key={`modal-iframe`} onCancel={handleClose} open={open} footer={[]}>
      <ReactPlayer playing={play} controls volume={1} url={link} />
    </Modal>
  );
}

export default ModalVideo;
