import React from "react";
import { RollbackOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import { Col, Row, Space, Statistic, Button, Form, Input, Checkbox } from "antd";
import Bg404 from "../../../assets/images/banner-404.jpg";
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Row className="mobile__bg404" style={{ backgroundImage: `url(${Bg404})`, minHeight: "calc(100vh - 85px)" }}>
      <Col span={24} className="d-flex flex-column jc-center ai-center">
        <p className="f-26 white text-center">
          <b>Desculpe, página não encontrada!</b>
          <br />
          Caso precise de assistência, entre em contato conosco.
          <br /> Agradecemos a sua compreensão!
        </p>
        <Link to="/">
          <Button className="mt-30" type="primary" size="large">
            <RollbackOutlined />
            Homepage
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export default PageNotFound;
