import ReactDOM from "react-dom";
import React, { Component, useEffect, useState } from "react";
import dayjs from 'dayjs'

import { createReactEditorJS } from "react-editor-js";

import { EDITOR_JS_TOOLS } from "./constants";
import { Spin } from "antd";

const defaultValue = {
  time: dayjs().unix(),
  blocks: [{
    type: "header",
    data: {
      text: "Content right here",
      level: 2
    }
  }]
}

const ReactEditorJS = createReactEditorJS();

const Editor = ({ type, value, index, onChange }) => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (type === 'update') {
      if (value) {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false);
    }
  }, [value]);

  function handleChangeEditor(e) {
    if (e) {
      e.saver.save().then((outputData) => {
        console.log(outputData);
        onChange(outputData, index);
      }).catch((error) => {
        console.log('Saving failed: ', error)
      });
    }
  }

  return (
    !isLoading ?
      <ReactEditorJS
        defaultValue={value}
        tools={EDITOR_JS_TOOLS}
        onChange={handleChangeEditor}
      />
      :
      <Spin spinning={isLoading} />
  );
}

export default Editor;