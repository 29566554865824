import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Divider, DatePicker, Spin, Table } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined, FileOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Import({ open, close }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [usersToImport, setUsersToImport] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [results, setResults] = useState({});

  const [form] = useForm();

  const uploadProps = {
    accept: ".csv",
    name: "file",
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      console.log(newFileList);
      setFileList(newFileList);
      setTableColumns([]);
      setTableData([]);
      setUsersToImport([]);
    },
    beforeUpload: (file) => {
      console.log(file);
      setFileList([file]);
      handleReadFile(file);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    fileList,
    defaultFileList: [],
  };

  function handleReadFile(file) {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("file", file);

    fetch(`${config.server_ip}${endpoints.user.readFileImport}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => {
        res
          .json()
          .then((responseData) => {
            let auxTableData = [];

            for (let i = 0; i < responseData.length; i++) {
              auxTableData.push(responseData[i]);
            }
            console.log(auxTableData);

            let tableColumnsArray = Object.keys(responseData[0]);
            let auxTableColumns = [];

            for (let z = 0; z < tableColumnsArray.length; z++) {
              auxTableColumns.push({
                title: tableColumnsArray[z],
                dataIndex: tableColumnsArray[z],
                key: z,
                width: "50%",
              });
            }

            setTableData(auxTableData);
            setTableColumns(auxTableColumns);
            setUsersToImport(responseData);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handleClose() {
    close();
    setFileList([]);
    setUsersToImport([]);
    setShowResult(false);
    setResults({});
    form.resetFields();
  }

  async function handleSubmit(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    let email_success = [];
    let email_error = [];
    let total_emails = 0;
    let limit_bulk = values.limit_bulk ? values.limit_bulk : usersToImport.length;
    let auxUsersToImport = usersToImport.slice(0, limit_bulk);
    let count = 0;
    let newUsers = [];
    let users = [];

    console.log(values);

    handleManageImportData(
      auxUsersToImport,
      limit_bulk,
      count,
      email_success,
      email_error,
      total_emails,
      values.sendEmail,
      values.typeOfImport,
      values.tableOfImport,
      newUsers,
      users
    )
      .then((res) => {
        console.log(res);
        let auxTableData = [];

        for (let i = 0; i < res.users.length; i++) {
          let isNewUser = newUsers.filter((item) => item.email === res.users[i].email);
          let emailSent = email_success.filter((item) => item.email === res.users[i].email);
          auxTableData.push({
            ...res.users[i],
            status: isNewUser.length > 0 ? "inserted" : values.typeOfImport === "skip" ? "skipped" : "updated",
            email_sent: values.sendEmail ? (emailSent.length > 0 ? "sent" : "not_sent") : "skip",
          });
        }

        let aux = {
          data: auxTableData,
          total_emails: res.total_emails,
          email_success: res.email_success.length,
          email_error: res.email_error.length,
          total_users: res.users.length,
          newUsers: res.newUsers,
        };

        setShowResult(true);
        setResults(aux);
        setIsLoading(false);
        setIsButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsButtonLoading(false);
      });
  }

  async function handleManageImportData(data, limit_bulk, count, email_success, email_error, total_emails, sendEmail, typeOfImport, tableOfImport, newUsers, users) {
    return handleImport(data, sendEmail, typeOfImport, tableOfImport).then((importedData) => {
      email_success.push(...importedData.data.email_success);
      email_error.push(...importedData.data.email_error);
      newUsers.push(...importedData.data.newUsers);
      users.push(...importedData.data.users);
      total_emails += importedData.data.total_emails;

      count++;
      let auxUsersToImport = usersToImport.slice(count * limit_bulk, limit_bulk * count + limit_bulk);

      if (auxUsersToImport.length > 0) {
        return handleManageImportData(auxUsersToImport, limit_bulk, count, email_success, email_error, total_emails, sendEmail, typeOfImport, tableOfImport, newUsers, users);
      } else {
        return {
          total_users: usersToImport.length,
          email_success,
          email_error,
          total_emails,
          sendEmail,
          typeOfImport,
          tableOfImport,
          newUsers,
          users,
        };
      }
    });
  }

  function handleImport(e, sendEmail, typeOfImport, tableOfImport) {
    console.log(tableOfImport);
    return new Promise((resolve, reject) => {
      axios
        .post(tableOfImport === "users" ? endpoints.user.import : endpoints.events.import, {
          data: { users: e, sendEmail, typeOfImport, tableOfImport },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Importar utilizadores"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
    >
      <Spin spinning={isLoading} tip="Uploading...">
        {showResult ? (
          <Col span={24}>
            <p className="f-26 bold text-center">Utilizadores importados com sucesso!</p>
            <Row gutter={[24]} className="mt-30">
              <Col span={12}>
                <p className="f-20 text-center">Total de utilizadores:</p>
                <p className="f-26 bold text-center">{results.total_users}</p>
              </Col>
              <Col span={12}>
                <p className="f-20 text-center">Novos utilizadores:</p>
                <p className="f-26 bold text-center">{results.newUsers ? results.newUsers.length : 0}</p>
              </Col>
            </Row>
            <Row>
              <Table
                className="mt-40 w-100"
                columns={[
                  {
                    title: "Nome",
                    dataIndex: "name",
                  },
                  {
                    title: "E-mail",
                    dataIndex: "email",
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    filters: [
                      {
                        text: "Sucesso",
                        value: "success",
                      },
                      {
                        text: "Erro",
                        value: "error",
                      },
                    ],
                    onFilter: (value, record) => results.data.filter((item) => item.status === value)[0],
                  },
                  {
                    title: "E-mail",
                    dataIndex: "email_sent",
                    filters: [
                      {
                        text: "Sucesso",
                        value: "success",
                      },
                      {
                        text: "Erro",
                        value: "error",
                      },
                    ],
                    onFilter: (value, record) => results.data.filter((item) => item.status === value)[0],
                  },
                ]}
                dataSource={results.data?.map((item) => {
                  return {
                    name: item.name,
                    email: item.email,
                    status: item.status,
                    email_sent: item.email_sent,
                  };
                })}
                loading={isTableLoading}
              />
            </Row>
          </Col>
        ) : (
          <>
            <Dragger {...uploadProps} style={{ maxHeight: 200 }} className="import_users_dragger">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Import a CSV file, becareful with the columns.</p>
            </Dragger>
            {usersToImport.length > 0 && (
              <Row className="import_users_info">
                <Col span={24}>
                  <div className="f-20 mt-10 text-center">Nº de utilizadores a importar:</div>
                  <div className="f-36 mb-40 text-center">{usersToImport.length}</div>
                  <Row className="w-100">
                    <Col span={24}>
                      <Table className="import_users_table" columns={tableColumns} dataSource={tableData} loading={isTableLoading} scroll={{ x: 1 }} />
                      <Form
                        form={form}
                        id="form-import-users"
                        onFinish={handleSubmit}
                        initialValues={{
                          price: [{}],
                          options: [{}],
                        }}
                        className="form w-100"
                        autoComplete="off"
                        layout="vertical"
                      >
                        <Form.Item label="Tabela a importar" name="tableOfImport" rules={[{ required: true, message: "Este campo é obrigatório" }]}>
                          <Select
                            placeholder="Tabela a importar"
                            size="large"
                            options={[
                              { value: "users", label: "users" },
                              { value: "events_users", label: "events_users" },
                            ]}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          />
                        </Form.Item>
                        <Form.Item label="Limit bulk" name="limit_bulk">
                          <InputNumber className="w-100" size="large" placeholder="0" />
                        </Form.Item>
                        <Form.Item label="Enviar e-mail a novos utilizadores" name="sendEmail" rules={[{ required: true, message: "Este campo é obrigatório" }]}>
                          <Radio.Group>
                            <Radio value={true}>Sim</Radio>
                            <Radio value={false}>Não</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Tipo de importação" name="typeOfImport" rules={[{ required: true, message: "Este campo é obrigatório" }]}>
                          <Radio.Group>
                            <Radio value={"update"}>Update</Radio>
                            <Radio value={"skip"}>Skip</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {!showResult && (
                          <Button type="primary" className="submit" onClick={form.submit} loading={isButtonLoading}>
                            Importar utilizadores
                          </Button>
                        )}
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )}
      </Spin>
    </Drawer>
  );
}

export default Import;
