import React, { useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import BannerGuiasPraticos from "../../../components/website/BannerGuiasPraticos/BannerGuiasPraticos";
import ListGuiasPraticos from "../../../components/website/ListGuiasPraticos/ListGuiasPraticos";
import { Button, Col, Empty, Row, Spin, Statistic } from "antd";
import { Helmet } from "react-helmet";
import BgClinicalCases from "../../../assets/images/novos-casos-clinicos-banner@2x.png";
import BialImage from "../../../assets/images/BIAL.png";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import "./ClinicalCases.css";
import config from "../../../services/config";

const { Countdown } = Statistic;
const ClinicalCases = () => {
  const [data, setData] = useState([]);
  const [firstItem, setFirstItem] = useState({});
  const [otherItems, setOtherItems] = useState([]);
  const [countdowns, setCoundowns] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.clinicalCases.read)
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data[0].length > 0) {
          const sorted = res.data[0].sort((a, b) => {
            return a.position - b.position;
          });

          for (let i = 0; i < sorted.length; i++) {
            let today = dayjs();
            let dayOfPublish = dayjs(sorted[i].publish_date);
            sorted[i].patient_info = JSON.parse(sorted[i].patient_info);
            if (today.diff(dayOfPublish) > 0) {
              sorted[i].is_active = true;
            } else {
              sorted[i].is_active = false;
            }
          }

          setFirstItem(sorted[0]);
          setOtherItems(sorted.filter((item) => item.id !== sorted[0].id));
          setData(sorted);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleCalcDate(item) {
    let newData = JSON.parse(JSON.stringify(data));
    let filteredObject = data.filter((i) => i.id === item.id)[0];
    if (filteredObject) {
      filteredObject.is_active = true;
    }
    const i = data.findIndex((x) => x.id === item.id);
    newData[i] = filteredObject;
    setData(newData);
  }

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>Casos Clínicos | Cardio4All</title>
        <meta name="description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Guias Práticos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta
          property="og:title"
          content="Guias Práticos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:description" content="Guias Práticos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BgClinicalCases}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BannerPrincipal
        color={"#E1EDF8"}
        backgroundImage={BgClinicalCases}
        children={
          <>
            <p className="f-40 blue">
              Bem-vindos aos <b>Novos desafios</b>
            </p>
            <p className="f-18 mt-10 blue">Diabetes - Insuficiência Cardíaca - Doença Renal</p>
          </>
        }
      />
      {Object.keys(firstItem).length > 0 ? (
        <Row>
          <Col span={24}>
            <Row style={{ backgroundColor: "#F5F5F5" }}>
              <Row gutter={[24]} className="container mt-40">
                <Col xs={24} sm={24} md={6} lg={3} xl={3} className="d-flex flex-column jc-center">
                  <img className="w-100 maxw-200 patient_img" src={config.server_ip + "/assets/media/" + firstItem.image} />
                </Col>
                <Col xs={24} sm={24} md={18} lg={21} xl={21} className="d-flex flex-column jc-center">
                  <h1 className="f-36 bold blue">{firstItem.title}</h1>
                  <p className="f-26 blue">{firstItem.text}</p>
                </Col>
                {!firstItem.is_active && (
                  <Col span={24}>
                    <p className="blue f-20 mt-40 mb-40 text-center">
                      Disponível <span className="text-uppercase">{dayjs(firstItem.publish_date).format("DD MMM")}</span>
                    </p>
                  </Col>
                )}
              </Row>
              {firstItem.is_active && firstItem.patient_info && (
                <div className="container">
                  <Row gutter={[24]} className="mt-20">
                    {firstItem.patient_info.map((item) => (
                      <Col
                        className="mt-10"
                        xs={24}
                        sm={24}
                        md={24 / firstItem.patient_info.length}
                        lg={24 / firstItem.patient_info.length}
                        xl={24 / firstItem.patient_info.length}
                      >
                        <div className="card-patient-info p-20 h-100">
                          <p className="mb-10 bold blue">{item.title}</p>
                          <p className="blue" dangerouslySetInnerHTML={{ __html: item.text }} />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              {firstItem.is_active && (
                <div className="container mt-40">
                  <div className="patient-description" dangerouslySetInnerHTML={{ __html: firstItem.description }}></div>
                </div>
              )}
              {firstItem.is_active && (
                <div className="container mt-40 d-flex jc-center ai-center pb-60">
                  <Link to={`/casos-clinicos/${firstItem.slug}`}>
                    <Button danger type="primary" size="large">
                      Caso clínico completo
                    </Button>
                  </Link>
                </div>
              )}
            </Row>
            <Row gutter={[60]} className="container mt-20 mb-50">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: "auto",
                    width: "auto",
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                }}
                className="mySwiper d-flex jc-start ai-start mt-30 w-100"
              >
                {otherItems.slice(0, 3).map((card, index) => {
                  return (
                    <SwiperSlide key={"swiper-card-" + card.id} id={card.id} className={`swiper-card`}>
                      <Row align={"bottom"} className={`swipe-card ${card.is_active ? "active" : "inactive"}`}>
                        <Col
                          span={8}
                          className="h-100 d-flex jc-end ai-end p-0"
                          style={{
                            backgroundImage: `url(${config.server_ip}/assets/media/${card.image})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            filter: card.is_active ? "none" : "grayscale(100%)",
                          }}
                        >
                          <div className="d-flex white bold f-60 mr-10" style={{ lineHeight: "50px" }}>
                            {card.position}
                          </div>
                        </Col>
                        <Col span={16} className="p-20 h-100">
                          <div className="d-flex flex-column jc-sb h-100">
                            <div>
                              <p className="f-22 bold m-0" style={{ color: card.is_active ? "#0E426A" : "#9C9DA1" }}>
                                {card.name}
                              </p>
                              <p className="mt-10" style={{ color: card.is_active ? "#87A1B5" : "#9C9DA1" }}>
                                {card.type}
                              </p>
                            </div>
                            <div>
                              {card.is_active ? (
                                <Link to={`/casos-clinicos/${card.slug}`} type="text" className="f-18" style={{ color: "#F04C4B" }}>
                                  Participar
                                </Link>
                              ) : (
                                <p className="bold" style={{ color: card.is_active ? "#87A1B5" : "#9C9DA1" }}>
                                  Dísponvel a <span className="text-uppercase">{dayjs(card.publish_date).format("DD MMM")}</span>
                                </p>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Row>
          </Col>
          <Col span={24} style={{ backgroundColor: "#F5F5F5" }}>
            <Row className="container pt-40 pb-40">
              <Col span={24} align="middle">
                <p className="blue">Com o patrocínio independente de:</p>
                <img src={BialImage} className="w-100 maxw-160" />
                <p className="f-12 blue italic">O patrocinador destes conteúdos Cardio4all não tem qualquer influência na criação dos mesmos</p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Row className="container d-flex jc-center ai-center mt-40 mb-40">
              <Empty description={<p className="blue">Ainda não existem casos clínicos disponíveis</p>}>
                <Link to="/">
                  <Button type="primary" size="large">
                    Home
                  </Button>
                </Link>
              </Empty>
            </Row>
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default ClinicalCases;
