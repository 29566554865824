import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import endpoints from "../../../services/endpoints";
import { Col, Row, Spin } from "antd";
import BannerVideoImage from "../../../assets/images/bg@2x.png";
import BannerCourses from "../../../components/website/BannerCourses/BannerCourses";
import { useParams } from "react-router-dom";
import ListCourses from "../../../components/website/ListCourses/ListCourses";
import BannerPrincipal from "../../../components/website/BannerPrincipal/BannerPrincipal";
import CalculatorCTA from "../../../components/website/CalculatorCTA/CalculatorCTA";

function CoursesDetails() {
  const [data, setData] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      handleGetData();
    }
  }, []);

  const handleGetData = () => {
    console.log(slug);
    axios
      .get(endpoints.course.readBySlug, {
        params: { slug: slug },
      })
      .then((res) => {
        console.log(res);
        setData(res.data[0][0]);
        setChapters(res.data[1]);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Helmet>
        <title>{`${data?.name}`} | Cardio4All</title>
        <meta name="description" content="Vídeos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta
          name="keywords"
          content="Vídeos do Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Vídeos do Cardio4all de a atualização científica e com as últimas guidelines de diagnóstico e tratamento da doença cardiovascular" />
        <meta property="og:description" content="Vídeos do Cardio4all úteis para a prática clínica e formação médica em Medicina Geral e Familiar e de Medicina Interna." />
        <meta property="og:image" content={`${BannerVideoImage}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <BannerPrincipal
        color={"#C3D0DA"}
        backgroundImage={""}
        children={
          <>
            <p className="f-20" style={{ color: "#4A718F" }}>
              Curso
            </p>
            <p className="blue f-26 bold mt-10 mb-0">{data.name}</p>
          </>
        }
      />
      <ListCourses data={chapters} />
      <CalculatorCTA />
    </Spin>
  );
}

export default CoursesDetails;
