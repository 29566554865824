import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Button,
  Modal,
  Card,
  Upload,
  Empty,
  message,
  Pagination,
  Table,
} from "antd";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import Create from "../../../components/dashboard/courses/Create";
import Update from "../../../components/dashboard/courses/Update";
import Delete from "../../../components/dashboard/courses/Delete";
import "../../../assets/styles/admin.css";

const { Meta } = Card;
const { confirm } = Modal;

export default function DashboardCourses() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      width: 100,
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    handleGetBanners();
  }, []);

  function handleGetBanners() {
    axios
      .get(endpoints.course.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function handlePrepareData(data) {
    let newData = [];
    data.forEach((element, index) => {
      newData.push({
        key: element.position,
        name: element.name,
        slug: element.slug,
        actions: (
          <div className="d-flex jc-end">
            <ArrowRightOutlined
              className="mr-10"
              key="edit"
              onClick={() => navigate(`${element.id}`)}
            />
            <EditOutlined
              className="mr-10"
              key="edit"
              onClick={() => handleOpenUpdate(element)}
            />
            <DeleteOutlined
              key="delete"
              onClick={() => handleOpenDelete(element)}
            />
          </div>
        ),
      });
    });

    setTableData(newData);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetBanners();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetBanners();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetBanners();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update
        data={selectedData}
        open={isOpenUpdate}
        close={handleCloseUpdate}
      />
      <Delete
        data={selectedData}
        open={isOpenDelete}
        close={handleCloseDelete}
      />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Cursos</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Table loading={isLoading} columns={columns} dataSource={tableData} />
      </Col>
    </Row>
  );
}
