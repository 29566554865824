import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Drawer,
  Row,
  Select,
  message,
  Space,
  Switch,
  Upload,
  Input,
  Divider,
  Steps,
  Table
} from 'antd';
import '../../../assets/styles/admin.css'
import axios from 'axios';
import api from '../../../services/api';
import { Outlet, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined, DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { useForm } from "antd/es/form/Form";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import 'react-quill/dist/quill.snow.css';
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function ExportData({ data, columns }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="blue f-20 mb-30 mt-30">Vão ser exportados:</p>
      <p className="bold blue f-40 mt-15">{data.length}</p>
      <p className="blue f-20 mb-30">utilizadores</p>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1 }}
      />
    </div>
  );
};

export default ExportData;