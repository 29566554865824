import { useState, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";

const Main = () => {
    return(
        <div> Bem-vindo ao dashboard do Cardio4all </div>
    )
};

export default Main;