import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Button,
  Modal,
  Card,
  Upload,
  Empty,
  message,
  Pagination,
  Tooltip,
} from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Update from "../../../components/dashboard/guide/Update";
import Create from "../../../components/dashboard/guide/Create";
import Delete from "../../../components/dashboard/guide/Delete";
import axios from "axios";
import endpoints from "../../../services/endpoints";
import config from "../../../services/config";
import "../../../assets/styles/admin.css";

const { Meta } = Card;
const { confirm } = Modal;

const DashboardGuides = () => {
  const [guides, setGuides] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetGuides();
  }, []);

  function handleGetGuides() {
    axios
      .get(endpoints.guides.read)
      .then((res) => {
        setGuides(res.data);
      })
      .catch((error) => console.error(error));
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetGuides();
  }

  function handleOpenUpdate(data) {
    setSelectedGuide(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetGuides();
  }

  function handleOpenDelete(data) {
    setSelectedGuide(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedGuide({});
    setIsOpenDelete(false);
    handleGetGuides();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <Row>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update
        open={isOpenUpdate}
        close={handleCloseUpdate}
        data={selectedGuide}
      />
      <Delete
        open={isOpenDelete}
        close={handleCloseDelete}
        data={selectedGuide}
      />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col>
            <h2 className="m-0">Guias</h2>
          </Col>
          <Col>
            <Button size="large" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Row gutter={[0, 24]} className="m-0">
          {guides.length === 0 && (
            <div style={{ width: "100%", display: "block", margin: "0 auto" }}>
              <Empty
                description="Não foram encontrados guias"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          )}
          {guides?.slice(minValue, maxValue).map((item) => {
            return (
              <Col span={24} key={item.id} className="p-0">
                <Row gutter={[24]} className="dashboard-article-item m-0">
                  <Col span={3}>
                    <p className="f-11 mb-5">Imagem</p>
                    <img
                      src={`${config.server_ip}/assets/media/${item.image}`}
                      className="w-100"
                    />
                  </Col>
                  <Col span={12}>
                    <p className="f-11 mb-5">Título</p>
                    <p className="ellipsisThree bold f-18">{item.title}</p>
                  </Col>
                  <Col span={4}>
                    <p className="f-11 text-center mb-5">Módulos</p>
                    <p className="f-22 text-center bold">
                      {item.modules_length}
                    </p>
                  </Col>
                  <Col span={4}>
                    <p className="f-11 text-center mb-5">Posição</p>
                    <p className="f-22 text-center bold">{item.position}</p>
                  </Col>
                  <Col
                    span={1}
                    className="d-flex flex-column jc-center ai-center"
                  >
                    <Tooltip title="Pré-visualizar">
                      <ArrowRightOutlined
                        key="edit"
                        className="f-20 mb-20"
                        onClick={() => navigate(`/admin/guides/${item.id}`)}
                      />
                    </Tooltip>
                    <Tooltip title="Editar">
                      <EditOutlined
                        key="edit"
                        className="f-20 mb-20"
                        onClick={() => handleOpenUpdate(item)}
                      />
                    </Tooltip>
                    <Tooltip title="Apagar">
                      <DeleteOutlined
                        key="delete"
                        className="f-20"
                        onClick={() => handleOpenDelete(item)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            );
          })}

          <Row className="ml-0 mr-0" align="center">
            <Pagination
              showSizeChanger={false}
              onChange={handleChangePage}
              pageSize={itemsPerPage}
              defaultCurrent={1}
              current={currentPage}
              total={guides.length}
            />
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardGuides;
