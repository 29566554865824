import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../assets/styles/calculator.css";
import { Col, Row, Button } from "antd";
import endpoints from "../../../services/endpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import SwipeImage from "../../../assets/images/swipe.svg";
import { Link, useNavigate } from "react-router-dom";

import "./CalculatorLinks.css";

export const CalculatorLinks = (props) => {
  const navigate = useNavigate();

  const [calculators, setCalculators] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (true) {
      axios
        .get(endpoints.calculators.read)
        .then((res) => {
          const data = res.data;
          const currentURL = window.location.href;
          const splitURL = currentURL.split("calculadoras");
          const filteredLinks = data.filter((item) => !item.slug.includes(splitURL[1]));
          /* order calc list */

          console.log(filteredLinks);
          setCalculators(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row>
      <Row className="container_fluid pt-40 pb-40" align={"middle"}>
        <Row className="container jc-center">
          <Col span={12} style={{ borderTop: "1px solid #9C9DA1", borderRadius: "10px" }}></Col>
        </Row>
      </Row>
      <Row className="container_fluid pt-40 pb-40">
        <Row className="container">
          <Col span={24}>
            <h3 className="f-36 text-center" style={{ color: "#4A718F" }}>
              Outras calculadoras
            </h3>
          </Col>
        </Row>
      </Row>
      <Row className="pb-40 container">
        <Col span={24} className="d-flex jc-center ai-center flex-column swiper-container">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: "auto",
                width: "auto",
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            className="w-100 d-flex jc-start ai-start mt-30"
          >
            {calculators.map((card, index) => {
              return (
                <SwiperSlide key={"swiper-card-" + card.id} id={card.id}>
                  <Row align={"bottom"}>
                    <Col className="swipe-card p-20" span={24}>
                      <div className="d-flex flex-column jc-sb ai-center h-100">
                        <div>
                          <p className="swipe-card-content f-18 bold m-0 ellipsisTwo text-center">{card.name}</p>
                          <p className="swipe-card-content f-18 mt-10 ellipsis-destaque ellipsisTwo">{card.description}</p>
                        </div>
                        <div>
                          <Link className="red" to={card.slug} type="text">
                            Saber mais
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Col>
        <Col span={24} className="d-flex jc-center ai-center mb-20">
          <span className="swiper-ver-mais">
            <b>{windowWidth <= 768 ? "Swipe" : "Clique e arraste"}</b> <img src={SwipeImage} alt="Swipe para ver mais" /> para ver mais
          </span>
        </Col>
      </Row>
    </Row>
  );
};
