import React, { useEffect, useState } from "react";
import { Col, Row, Space, Button, Form, Input, Checkbox, Spin, message } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ImageCoverBg from "../../../assets/images/bg-perfil@2x.png";
import endpoints from "../../../services/endpoints";
import "./ResetPassword.css";
import GoogleReCAPTCHA from "../../../services/recaptcha";

const ResetPassword = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, [user]);

  function handleVerifyUser() {
    console.log(user);
    if (user) {
      navigate("/perfil");
    }
  }

  function handleLogin(values) {
    setIsButtonLoading(true);

    let tokenPassword = searchParams.get("token");
    let emailPassword = searchParams.get("email");
    let data = {
      token: tokenPassword,
      email: emailPassword,
      password: values.password,
    };

    axios
      .post(endpoints.auth.resetPassword, {
        data,
      })
      .then((res) => {
        console.log(res.data);
        setIsButtonLoading(false);
        message.success({
          content: "A sua password foi alterada com sucesso!",
        });
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        if (!err.token_valid) {
          message.error({
            content: "Este link não é válido. Por favor verifique se o link é o mesmo que enviamos para o seu e-mail.",
          });
        }
        setIsButtonLoading(false);
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row
        className="h-100 w-100 login_content"
        style={{
          minHeight: "calc(100vh - 150px)",
          backgroundImage: `url(${ImageCoverBg})`,
        }}
      >
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          <Row className="login-row d-flex jc-center ai-center">
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="d-flex flex-column jc-center ai-center">
              <p className="f-20 blue mb-30 text-center">
                Recupere aqui a sua{" "}
                <b>
                  <i>password</i>
                </b>{" "}
                para poder aceder à sua conta.
              </p>
              <Form className="login-form w-100" name="basic" onFinish={handleLogin} form={form}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      min: 8,
                      message: "A password deve ter pelo menos 8 caracteres",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Confirme a sua password, por favor!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("As passwords não correspondem"));
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" placeholder="Confirmar Password" />
                </Form.Item>
                <div className="d-flex jc-center ai-center">
                  <Button loading={isButtonLoading} type="primary" htmlType="submit" className="login-btn mt-20" disabled={!isVerified}>
                    Recuperar Password
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
