import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import { Col, Row, Button } from "antd";
import CalculadorasImage from "../../../assets/images/calculadoras-banner@2x.png";
import "./CalculatorCTA.css";

import { Outlet, Link } from "react-router-dom";

const CalculatorCTA = () => {
  return (
    <Row className="cta_calc pb-100 pt-100">
      <Col span={24} className="container">
        <div className="card">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={15} lg={15} xl={15} className="pl-80 pt-20 pb-20 d-flex flex-column jc-center">
              <p className="f-44 white bold">Conheça as nossas Calculadoras</p>
              <p className="white f-18 mt-20">
                O Cardio4all desenvolveu várias calculadoras úteis para a sua prática clínica que podem ser acedidas aqui, ou através da <b>App Cardio4all</b>.
              </p>
              <Link to="/calculadoras" className="mt-20">
                <Button size="large" type="primary" className="mt-20">
                  Ver mais
                </Button>
              </Link>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} className="d-flex jc-end ai-end">
              <img src={CalculadorasImage} className="w-100" style={{ height: "auto" }} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default CalculatorCTA;
