import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";

import { Col, Row, Card, Select, Space, Button, Form, Input, Checkbox, Tabs, Empty } from "antd";
import BannerSummit from "../../../assets/images/banner-summit-2023.png";
import FiltroIcon from "../../../assets/images/filtro-icon.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import { Outlet, Link } from "react-router-dom";
import config from "../../../services/config";
import IconCalendarImage from "../../../assets/images/calendario-icon.svg";
import dayjs from "dayjs";
import "./ListVideocasts.css";
import ModalVideo from "../ModalVideo/ModalVideo";

const { Meta } = Card;

const PodcastsTab = ({ data }) => {
  const [itemsToShow, setItemsToShow] = useState(12);
  const month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 6);
  };

  return (
    <>
      {data.length > 0 ? (
        <Row
          gutter={[
            { xs: 40, sm: 8, md: 16, lg: 24 },
            { xs: 40, sm: 8, md: 16, lg: 24 },
          ]}
          className="mr-0 ml-0 d-flex"
        >
          {data?.slice(0, itemsToShow).map((item, index) => {
            return (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} key={"article-" + item?.id}>
                <iframe
                  style={{ borderRadius: "12px" }}
                  src={`${item.link}?utm_source=generator&theme=0`}
                  width="100%"
                  height="152"
                  frameBorder="0"
                  allowfullscreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                />

                {item?.button_text && item?.button_link && (
                  <Row className="mt-10">
                    <Col span={24} align="middle">
                      <Link to={item.button_link} target="_blank">
                        <Button type="primary" size="large">
                          {item.button_text}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                )}
              </Col>
            );
          })}
          <Row className="d-flex jc-center ai-center mt-40 mb-40">
            {data && itemsToShow < data?.length && (
              <Button type="primary" size="large" onClick={handleLoadMore}>
                Carregar mais
              </Button>
            )}
          </Row>
        </Row>
      ) : (
        <Empty description="Não foram encontrados podcasts" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default PodcastsTab;
